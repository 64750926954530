<template>
    <div class="frm_cashimport">
        <form action="" method="post" id="frm_uploads_img" @submit="cashinFrm">
            <input v-numeric-input="{ thousands: ',', align: 'left'}" id="pointcash" placeholder="Số tiền (*)" />
            <input type="file" @change="changenameFile" class="change_picture" name="cash_picture" id="cash_picture" />
            <label class="cash_picture" for="cash_picture">
                <span>Chọn ảnh</span>
            </label>
            <textarea v-model="note" id="notecash" cols="30" rows="3" placeholder="Ghi chú"></textarea>
            <button type="submit">Nạp tiền</button>
            <p class="text_ratio">Nạp <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span> nhận được <span>{{ xucode }}</span> xu</p>
            <p class="title_ratio">
                Nạp tối thiểu <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span>
            </p>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Cashin_live',
    data () {
        return {
            numberus: window.localStorage.getItem('numberus'),
            showmodal_cashin: false,
            amount_change: '',
            point_change: '',
            min_credit: '',
            max_credit: '',
            pointcode: '',
            xucode: '',
            amount_change_debit: '',
            min_debit: '',
            max_debit: '',
            valdebit: '',
        }
    },
    components: {
        
    },
    created () {
        this.getRatiocash()
    },
    mounted () {
        
    },
    methods: {
        cashinFrm (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('cash_picture').files[0]
            let point_money = $('#pointcash').val()
            if(file_img == undefined){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập hình ảnh',
                    showConfirmButton: false,
                    timer: 4000
                })
            }else{
                const form = new FormData();
                form.append('point', point_money);
                form.append('hinh_anh', file_img);
                // form.append('account_f8bet', this.accountf8);
                form.append('note', this.note);
                this.axios.post(this.api_cashin, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    this.showmodal_cashin = false
                    $('#pointcash').val('')
                    this.accountf8 = this.note = this.point = ''
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/thong-tin/' + this.numberus
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 403){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        getRatiocash () {
            this.axios.get(this.api_ratiocash).then(res => {
                this.amount_change = res.data.data.amount_change
                this.point_change = res.data.data.point_change
                this.min_credit = res.data.data.min_credit
                this.max_credit = res.data.data.max_credit
                this.pointcode = this.min_credit * this.point_change
                this.xucode = this.min_credit * this.amount_change

                this.amount_change_debit = res.data.data.amount_change_debit
                this.min_debit = res.data.data.min_debit
                this.max_debit = res.data.data.max_debit

                this.valdebit = this.amount_change_debit * this.min_debit
            })
        },
        changenameFile () {
            let fileVideo = document.getElementById('cash_picture').files[0]
            $('label[for="cash_picture"] span').text(fileVideo.name);
        },
    }
}
</script>
<style></style>
