<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_intro" style="margin-bottom: 30px;">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <li :class="{active: tabSelected === 'listRegister'}" v-on:click="changeTabs('listRegister')">Danh sách đăng ký</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelected === 'listRegister'">
                            <div class="wrapper_content_cn">
                                <div class="list_chanel">
                                    <h4>Danh sách đăng ký idol</h4>
                                    <table class="table_chanel" style="margin-bottom: 15px;">
                                        <thead>
                                            <tr>
                                                <th width="7%">STT</th>
                                                <th width="20%">Họ tên</th>
                                                <th width="20%">Tên đăng nhập</th>
                                                <th width="23%">Email</th>
                                                <th width="15%">Sđt</th>
                                                <th width="15%">Trạng thái</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="listChanel.length > 0">
                                            <tr v-for="(item, index) in datas" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    {{ item.fullname }}
                                                </td>
                                                <td>{{ (item.username) }}</td>
                                                <td>{{ (item.email) }}</td>
                                                <td>{{ (item.phone) }}</td>
                                                <td v-if="item.status == 0" style="color: #0084f8;">Chờ duyệt</td>
                                                <td v-else-if="item.status == 1" style="color: #00cf00;">Đã duyệt</td>
                                                <td v-else style="color: red;">Hủy</td>
                                            </tr>
                                        </tbody>
                                        <tbody v-else>
                                            <tr>
                                                <td colspan="6">Chưa đăng ký</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'ListRg_nm',
    data () {
        return {
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            idUser: parseInt(this.$route.params.id),
            token: window.localStorage.getItem('token'),
            usIdol: window.localStorage.getItem('us'),
            tabSelected: 'listRegister',
            listChanel: [],
            logoWb: ''
        }
    },
    components: {
    },
    created () {
        this.getListChanel()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListChanel () {
            this.axios.get(this.api_listRegister, {
                headers: {
                    Authorization: 'Bearer ' + this.token
                }
            }).then((response) => {
                this.listChanel = response.data.data.reg_idol
                this.totalCount = this.listChanel.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listChanel.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            var gpg = splitdate[1].split(':');
            return  gpg[0] + ':' + gpg[1] + ' ' + year + '-' + month + '-' + day;
        },
    }
}
</script>
<style>
</style>
