<template>
    <div id="content" class="content-margin">
        <div class="root_video_live">
            <div class="title_item_idol">
                <h3>
                    <img src="../assets/images/ddx.png" alt="">
                    <span>Idol</span>
                </h3>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'DetailLiveBG_nm', params: { id: item.bigo_id } }" @click="getLocalstoragePage">
                            <div class="img_live">
                                <img :src="item.cover_l" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live">
                                <div class="img_live_small">
                                    <img :src="item.data1" alt="">
                                </div>
                                <div class="text_live_small">
                                    <h3>{{ item.nick_name.substring(0, 8) }}</h3>
                                    <div class="type_live">
                                        <p>
                                            <img src="../assets/images/eye.svg" alt="">
                                            <span class="sp-02">{{ item.user_count }}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'LiveBg_nm',
    data () {
        return {
            listLiveBg: [],
            page: 1,
            perPage: 25,
            totalCount: 0,
            datas: []
        }
    },
    components: {
    },
    mounted() {
        
    },
    created () {
        this.getDataliveBg()
    },
    methods: {
        getDataliveBg () {
            this.axios.get(this.api_listBG).then((response) => {
                this.listLiveBg = this.shuffle(response.data.data)
                this.totalCount = this.listLiveBg.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listLiveBg.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
    }
}
</script>
<style>
</style>
