<template>
    <div class="root_register">
        <form action="" method="post" id="frm_register" @submit="register">
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12 left_frm_register">
                    <div class="wrapper_left_register">
                        <div class="input_dk">
                            <label><img src="../assets/images/ht.png" alt=""> Họ tên</label>
                            <input type="text" v-model="name" placeholder="">
                            <p class="lable_title"><span>*</span> Viết đầy đủ họ tên</p>
                        </div>
                        <div class="input_dk">
                            <label><img src="../assets/images/us1.png" alt=""> Tên đăng nhập</label>
                            <input type="text" v-model="username" placeholder="">
                            <p class="lable_title"><span>*</span> Từ 3 - 15 ký tự, bằng chữ, không thêm ký tự đặc biệt</p>
                        </div>
                        <div class="input_dk">
                            <label><img src="../assets/images/pw1.png" alt=""> Mật khẩu thành viên</label>
                            <input type="password" v-model="password" placeholder="">
                            <p class="lable_title"><span>*</span> Lớn hơn 6 ký tự</p>
                        </div>
                        <div class="input_dk">
                            <label><img src="../assets/images/pw1.png" alt=""> Xác nhận mật khẩu</label>
                            <input type="password" v-model="password_confirm" placeholder="">
                            <p class="lable_title"><span>*</span> Vui lòng xác nhận lại mật khẩu của bạn</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6 col-xs-12 right_frm_register">
                    <div class="wrapper_left_register">
                        <div class="input_dk">
                            <label><img src="../assets/images/em.png" alt=""> Địa chỉ email</label>
                            <input type="email" v-model="email" placeholder="">
                            <p class="lable_title"><span>*</span> Nhập chính xác để khôi phục lại mật khẩu khi quên</p>
                        </div>
                        <div class="input_dk">
                            <label><img src="../assets/images/dt.png" alt=""> Số điện thoại</label>
                            <input type="text" v-model="phone" placeholder="">
                            <p class="lable_title"><span>*</span> Số điện thoại 10 số, Ví dụ: +84 837 999 988</p>
                        </div>
                        <div class="input_dk">
                            <label><img src="../assets/images/ma.png" alt=""> Mã xác minh</label>
                            <div class="codecapchar_dk">
                                <input type="text" class="codeCapchar" placeholder="">
                                <div class="capcharCheck">
                                    <span>{{ codeCapchar }}</span>
                                    <button type="button" @click="reloadCapchar" class="resetcapchar"></button>
                                </div>
                            </div>
                            <p class="lable_title"><span>*</span> Vui lòng nhập đúng mã xác minh bên cạnh</p>
                        </div>
                        <div class="input_dk input0">
                            <!-- <p class="checkagea"><img src="../assets/images/check.png" alt=""> Tôi đã đủ tuổi và đồng ý <span>"Chính sách thành viên"</span></p> -->
                            <div class="checkbox-wrapper-4">
                                <input class="inp-cbx" id="checkDk" @change="changeDisable" type="checkbox"/>
                                <label class="cbx" for="checkDk">
                                    <span>
                                        <svg width="12px" height="10px">
                                            <use xlink:href="#check-4"></use>
                                        </svg>
                                    </span>
                                    <span>Tôi đồng ý <b>"Chính sách thành viên"</b></span>
                                </label>
                                <svg class="inline-svg">
                                    <symbol id="check-4" viewbox="0 0 12 10">
                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </symbol>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_dangky">
                <button type="submit" id="registerBtn" class="disableCss" disabled>Đăng ký ngay</button>
                <button type="button" @click="handleClick">Đăng nhập</button>
            </div>
        </form>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'Register_f8',
    data () {
        return {
            codeCapchar: Math.floor(1000 + Math.random() * 9000),
            name: '',
            email: '',
            phone: '',
            username: '',
            password: '',
            password_confirm: '',
            codeParam: ''
        }
    },
    components: {
    },
    created () {
    },
    methods: {
        register (e) {
            e.preventDefault()
            document.getElementById("registerBtn").removeAttribute("disabled", "");
            let capchar = $('.codeCapchar').val()
            if(capchar == ''){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập mã xác nhận',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else if(capchar != this.codeCapchar){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Mã xác nhận không đúng',
                    showConfirmButton: false,
                    timer: 3000
                })
            }else{
                if (document.getElementById('checkDk').checked) {
                    $('.loading_show').show().fadeIn(10)
                    this.axios.post(this.api_register, {
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        username: this.username,
                        password: this.password,
                        password_confirm: this.password_confirm,
                        referrer: this.codeParam
                    }).then(res => {
                        $('#loading_show').css('display', 'none')
                        this.$swal({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.message,
                            showConfirmButton: false,
                            timer: 4000
                        })
                        this.showModal_dk = false
                        this.name = this.username = this.email = this.password = this.password_confirm = this.phone = ''
                        window.location.reload()
                    }).catch(e => {
                        $('#loading_show').css('display', 'none')
                        if (e.response.status === 403) {
                            this.$swal({
                                position: 'top-end',
                                icon: 'error',
                                title: e.response.data.message,
                                showConfirmButton: false,
                                timer: 3000
                            })
                        }
                    })
                }
            }
        },
        reloadCapchar () {
            this.codeCapchar = Math.floor(1000 + Math.random() * 9000)
        },
        handleClick () {
            this.$emit('showMd')
        },
        changeDisable () {
            if (document.getElementById('checkDk').checked) {
                document.getElementById("registerBtn").disabled = false;
                $("#registerBtn").removeClass('disableCss');
                this.$emit('showTextDk')
            }else{
                document.getElementById("registerBtn").disabled = true;
                $("#registerBtn").addClass('disableCss');
            }
        }
    }
}
</script>
<style>
</style>
