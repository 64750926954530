<template>
    <div id="content" class="content-margin">
        <div class="container">
            <div class="root_chart_idol">
                <div class="tab_chart_id">
                    <ul>
                        <li data_id="1" class="active" @click="getParamsChart($event)">Ngày</li>
                        <li data_id="2" @click="getParamsChart($event)">Tuần</li>
                        <li data_id="3" @click="getParamsChart($event)">Tháng</li>
                    </ul>
                </div>
                <div class="content_chart" v-if="listChart.length > 0">
                    <div class="wp_Listtop_chart">
                        <div class="top_one_chart">
                            <div class="img_topone">
                                <img src="../assets/images/c3.png" alt="" class="img_numberChart">
                                <img :src="link_web + listChart1.hinh_anh" alt="" class="img_userChart">
                            </div>
                            <p class="nameChart">
                                <span class="nameIdol_chart">{{ listChart1.name }}</span>
                                <span v-if="listChart1.sex == 1" class="img_sex_nam"><img src="../assets/images/nam.png" alt=""></span>
                                <span v-else class="img_sex_nu"><img src="../assets/images/nu.png" alt=""></span>
                                <span class="amount_chart">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(listChart1.amount) }}</span>
                            </p>
                        </div>
                        <div class="top_two_three_chart">
                            <div class="top_two_chart">
                                <div class="img_topone">
                                    <img src="../assets/images/c5.png" alt="" class="img_numberChart">
                                    <img :src="link_web + listChart2.hinh_anh" alt="" class="img_userChart">
                                </div>
                                <p class="nameChart">
                                    <span class="nameIdol_chart">{{ listChart2.name }}</span>
                                    <span v-if="listChart2.sex == 1" class="img_sex_nam"><img src="../assets/images/nam.png" alt=""></span>
                                    <span v-else class="img_sex_nu"><img src="../assets/images/nu.png" alt=""></span>
                                    <span class="amount_chart">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(listChart2.amount) }}</span>
                                </p>
                            </div>
                            <div class="top_three_chart">
                                <div class="img_topone">
                                    <img src="../assets/images/c7.png" alt="" class="img_numberChart">
                                    <img :src="link_web + listChart3.hinh_anh" alt="" class="img_userChart">
                                </div>
                                <p class="nameChart">
                                    <span class="nameIdol_chart">{{ listChart3.name }}</span>
                                    <span v-if="listChart3.sex == 1" class="img_sex_nam"><img src="../assets/images/nam.png" alt=""></span>
                                    <span v-else class="img_sex_nu"><img src="../assets/images/nu.png" alt=""></span>
                                    <span class="amount_chart">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(listChart3.amount) }}</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="wp_listBottom_chart">
                        <ul>
                            <li v-for="(item, index) in listChart" :key="index">
                                <span class="stt_chart">{{ index + 1 }}</span>
                                <div class="wp_infoChart">
                                    <img :src="link_web + item.hinh_anh" alt="" class="img_listchart">
                                    <span class="namelist_chart">{{ item.name }}</span>
                                    <span v-if="item.sex == 1" class="img_sex_listnam"><img src="../assets/images/nam.png" alt=""></span>
                                    <span v-else class="img_sex_listnu"><img src="../assets/images/nu.png" alt=""></span>
                                    <span class="amount_listchart">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }}</span>
                                </div>
                                <img src="../assets/images/livebxh.gif" v-if="item.live == 1" alt="" class="imgLiveChart">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
$( document ).ready(function() {
    $(document).on('click', '.tab_chart_id ul li', function() {
        $('.tab_chart_id ul li').removeClass('active');
        $(this).addClass('active');
    });
});
export default {
    name: 'Chart_nm',
    data () {
        return {
            type: 1,
            listChart: [],
            listChart1: [],
            listChart2: [],
            listChart3: [],
        }
    },
    components: {
    },
    mounted() {
        
    },
    created () {
        this.getDataChart()
    },
    methods: {
        getDataChart () {
            this.axios.get(this.api_listChart + '?type=' + this.type).then((response) => {
                this.listChart = response.data.data.list_user
                this.listChart1 = response.data.data.list_user[0]
                this.listChart2 = response.data.data.list_user[1]
                this.listChart3 = response.data.data.list_user[2]
            })
        },
        getParamsChart (event) {
            var paramChart = event.target.getAttribute('data_id')
            this.type = paramChart
            this.getDataChart()
        }
    }
}
</script>
<style>
</style>
