<template>
    <div class="root_chat">
        <div class="container-fluid">
            <div class="logo_menu_mobilechat">
                <div class="logochatmb">
                    <router-link to='/' title="">
                        <img :src="link_web + logoWb" alt="">
                    </router-link>
                </div>
                <div class="menuidolchat_mb">
                    <img src="../assets/images/mn.png" alt="" class="menushowmb">
                    <img src="../assets/images/mn1.png" alt="" class="menuhidemb">
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 col-sm-3 col-xs-3 wp_left_list_mes">
                    <div class="left_list_mes" ref="scroll">
                        <div class="logo_chat_f8">
                            <router-link to='/' title="">
                                <img :src="link_web + logoWb" alt="">
                            </router-link>
                        </div>
                        <div class="list_idol_chat">
                            <div class="form_search">
                                <form action="" method="">
                                    <input type="text" name="key_search" class="key_search" value="" placeholder="Search" @keyup="searchMes">
                                </form>
                            </div>
                            <div class="wp_chat_message">
                                <div class="chat_typeidol">
                                    <router-link v-bind:to="{ name: 'Chat_nm', params: { idUser: iduser, idIdol: 0 } }">
                                        <img src="../assets/images/chat_lf.png" alt="">
                                    </router-link>
                                </div>
                                <div class="list_chat_idol" id="heighScroll" ref="heighScroll" @scroll="scrollData">
                                    <div :id="'itemidolchatLeft'+item.id" class="item_idol_chat" :class="{active: idId != 0 && index == 0}" v-for="(item, index) in listIdolchat" :key="index" :data_id_idol="item.id" :data_name_idol="item.name" :data_pic_idol="item.avatar" @click="clickMesDetail($event)">
                                        <div class="img_idolchat" :style="{ 'border-color': item.color_code }" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                            <img :src="link_web + item.avatar" alt="" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                        </div>
                                        <div class="name_mes_left">
                                            <h2 :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">{{ item.name }}</h2>
                                            <p v-if="item.content != null && item.content.length > 15" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">{{ item.content.substring(0, 15) }}</p>
                                            <p v-else :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">{{ item.content }}</p>
                                        </div>
                                        <div class="typeMes" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                            <img src="../assets/images/bacham.png" alt="" v-if="idId == 0" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                            <ul :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                                <li v-for="(items, indexs) in listType" :key="indexs" :style="{ 'border-color': items.color_code }" :data_id_pl="items.id" :data_id_user="item.id" :data_color="items.color_code" @click="clickTypeMes($event)" :data_id_idol="item.id" :data_name_idol="item.name" :data_pic_idol="item.avatar">{{ items.name }}</li>
                                                <li :data_id_user="item.id" @click="DeleteTypeMes($event)" :data_id_idol="item.id" :data_name_idol="item.name" :data_pic_idol="item.avatar">Xóa phân loại</li>
                                            </ul>
                                        </div>
                                        <div class="date_mes_end" :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">
                                            <p :data_name_idol="item.name" :data_pic_idol="item.avatar" :data_id_idol="item.id">{{ item.m_time }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9 col-sm-9 col-xs-9 wp_right_mes">
                    <div class="right_mes">
                        <div class="list_chat_message" v-if="idId != 0">
                            <div class="info_idol_chat info_idol_chatId">
                                <h2>
                                    <img :src="link_web + imgIdol" alt="">
                                    <span>{{ nameidol }}</span>
                                </h2>
                            </div>
                            <div class="pinMessage">
                                <div class="contentPin" v-if="checkpin==1" @click="scrollPin(listPin.id)">
                                    <div class="textpin">
                                        <h4>Pin message {{listPin.author}}</h4>
                                        <p v-if="listPin.image != null" class="imgpinchat"><img :src="link_webchat + listPin.image" alt=""></p>
                                        <p v-else-if="listPin.file != null"><img src="../assets/images/file.png" alt="">{{ listPin.file_name }}</p>
                                        <p v-else>{{ listPin.content }}</p>
                                    </div>
                                    <div class="delePin">
                                        <img src="../assets/images/xoachat.png" alt="" :data_id="listPin.id" @click="deletePinMes($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="list_chat_detail_mes" :class="{ mgMobile : checkpin == 1}" @scroll="scrollDataDetailMes" ref="scrollMess">
                                <div class="item_chat_mes" :id="'itemMes'+item.id" v-for="(item, index) in listDetailMes" :key="index">
                                    <div class="leftidol_chat" v-if="idId == item.user_from">
                                        <div class="left_text_mes">
                                            <div class="avartaId">
                                                <img :src="link_web + item.avatar" alt="">
                                            </div>
                                            <div class="textMes">
                                                <div class="reply" v-if="item.author_reply != null">
                                                    <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                        <p class="authorrl">{{ item.author_reply }}</p>
                                                        <p v-if="item.image_reply != null" class="imgshowrep"><img :src="link_webchat + item.image_reply" alt=""></p>
                                                        <p v-else-if="item.file_name_reply != null"><img src="../assets/images/file.png" alt="">{{ item.file_name_reply }}</p>
                                                        <p v-else>{{ item.content_reply }}</p>
                                                    </a>
                                                </div>
                                                <p v-if="item.image != null"><img :src="link_webchat + item.image" alt=""></p>
                                                <p v-else-if="item.file != null"><a :href="link_webchat + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                                <p v-else-if="item.image_album != null" class="listImgchatshow">
                                                    <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                        <Fancybox>
                                                            <a data-fancybox="" :href="link_webchat + item2">
                                                                <img :src="link_webchat + item2" alt="">
                                                            </a>
                                                        </Fancybox>
                                                    </span>
                                                </p>
                                                <p v-else>{{ item.content }}</p>
                                                <span>{{ item.time + ' ' + item.date }}</span>
                                            </div>
                                            <div class="delete_mes_left">
                                                <img src="../assets/images/reply.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                                <img src="../assets/images/pin.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right_user_chat" v-else>
                                        <div class="right_text_mes">
                                            <div class="delete_mes_right">
                                                <img src="../assets/images/xoachat.png" alt="" :data_id="item.id" @click="deleteMes($event)">
                                                <img src="../assets/images/reply.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                                <img src="../assets/images/pin.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                            </div>
                                            <div class="textMes">
                                                <div class="reply" v-if="item.author_reply != null">
                                                    <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                        <p class="authorrl" :data_id="item.id_reply">{{ item.author_reply }}</p>
                                                        <p v-if="item.image_reply != null" class="imgshowrep" :data_id="item.id_reply"><img :src="link_webchat + item.image_reply" alt="" :data_id="item.id_reply"></p>
                                                        <p v-else-if="item.file_name_reply != null" :data_id="item.id_reply"><img src="../assets/images/file.png" alt="" :data_id="item.id_reply">{{ item.file_name_reply }}</p>
                                                        <p v-else class="pcontent-rep" :data_id="item.id_reply">{{ item.content_reply }}</p>
                                                    </a>
                                                </div>
                                                <p v-if="item.image != null"><img :src="link_webchat + item.image" alt=""></p>
                                                <p v-else-if="item.file != null"><a :href="link_webchat + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                                <p v-else-if="item.image_album != null" class="listImgchatshow">
                                                    <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                        <Fancybox>
                                                            <a data-fancybox="" :href="link_webchat + item2">
                                                                <img :src="link_webchat + item2" alt="">
                                                            </a>
                                                        </Fancybox>
                                                    </span>
                                                </p>
                                                <p v-else>{{ item.content }}</p>
                                                <span>{{ item.time + ' ' + item.date }}</span>
                                            </div>
                                            <div class="avartaId">
                                                <img :src="link_web + item.avatar" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="list_chat_message" v-else>
                            <div class="info_idol_chat info_idol_chatnoneid">
                                <h2>
                                    <img :src="link_web + picId" alt="">
                                    <span>{{ nameId }}</span>
                                </h2>
                            </div>
                            <div class="pinMessage">
                                <div class="contentPin" v-if="checkpin==1" @click="scrollPin(listPin.id)">
                                    <div class="textpin">
                                        <h4>Pin message {{listPin.author}}</h4>
                                        <p v-if="listPin.image != null" class="imgpinchat"><img :src="link_webchat + listPin.image" alt=""></p>
                                        <p v-else-if="listPin.file != null"><img src="../assets/images/file.png" alt="">{{ listPin.file_name }}</p>
                                        <p v-else>{{ listPin.content }}</p>
                                    </div>
                                    <div class="delePin">
                                        <img src="../assets/images/xoachat.png" alt="" :data_id="listPin.id" @click="deletePinMes($event)">
                                    </div>
                                </div>
                            </div>
                            <div class="list_chat_detail_mes" :class="{ mgMobile : checkpin == 1}" @scroll="scrollDataDetailMes" ref="scrollMess">
                                <div class="item_chat_mes" :id="'itemMes'+item.id" v-for="(item, index) in listDetailMes" :key="index" :ref="'itemMes'+item.id">
                                    <div class="leftidol_chat" v-if="idIdol == item.user_from">
                                        <div class="left_text_mes">
                                            <div class="avartaId">
                                                <img :src="link_web + item.avatar" alt="">
                                            </div>
                                            <div class="textMes">
                                                <div class="reply" v-if="item.author_reply != null">
                                                    <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                        <p class="authorrl">{{ item.author_reply }}</p>
                                                        <p v-if="item.image_reply != null" class="imgshowrep"><img :src="link_webchat + item.image_reply" alt=""></p>
                                                        <p v-else-if="item.file_name_reply != null"><img src="../assets/images/file.png" alt="">{{ item.file_name_reply }}</p>
                                                        <p v-else>{{ item.content_reply }}</p>
                                                    </a>
                                                </div>
                                                <p v-if="item.image != null"><img :src="link_webchat + item.image" alt=""></p>
                                                <p v-else-if="item.file != null"><a :href="link_webchat + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                                <p v-else-if="item.image_album != null" class="listImgchatshow">
                                                    <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                        <Fancybox>
                                                            <a data-fancybox="" :href="link_webchat + item2">
                                                                <img :src="link_webchat + item2" alt="">
                                                            </a>
                                                        </Fancybox>
                                                    </span>
                                                </p>
                                                <p v-else>{{ item.content }}</p>
                                                <span>{{ item.time + ' ' + item.date }}</span>
                                            </div>
                                            <div class="delete_mes_left">
                                                <img src="../assets/images/reply.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                                <img src="../assets/images/pin.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right_user_chat" v-else>
                                        <div class="right_text_mes">
                                            <div class="delete_mes_right">
                                                <img src="../assets/images/xoachat.png" alt="" :data_id="item.id" @click="deleteMes($event)">
                                                <img src="../assets/images/reply.png" alt="" :data_id="item.id" :data_name="item.author" :data_content="item.content" :data_img="item.image" :data_filename="item.file_name" class="replybtn" @click="repyChat($event)">
                                                <img src="../assets/images/pin.png" alt="" :data_id="item.id" class="pinbtn" @click="pinMes($event)">
                                            </div>
                                            <div class="textMes">
                                                <div class="reply" v-if="item.author_reply != null">
                                                    <a :data_id="item.id_reply" class="clickreplyrun" @click="scrollReply(item.id_reply)">
                                                        <p class="authorrl" :data_id="item.id_reply">{{ item.author_reply }}</p>
                                                        <p v-if="item.image_reply != null" class="imgshowrep" :data_id="item.id_reply"><img :src="link_webchat + item.image_reply" alt="" :data_id="item.id_reply"></p>
                                                        <p v-else-if="item.file_name_reply != null" :data_id="item.id_reply"><img src="../assets/images/file.png" alt="" :data_id="item.id_reply">{{ item.file_name_reply }}</p>
                                                        <p v-else class="pcontent-rep" :data_id="item.id_reply">{{ item.content_reply }}</p>
                                                    </a>
                                                </div>
                                                <p v-if="item.image != null"><img :src="link_webchat + item.image" alt=""></p>
                                                <p v-else-if="item.file != null"><a :href="link_webchat + item.file" target="_blank"><img src="../assets/images/file.png" alt="">{{ item.file_name }}</a></p>
                                                <p v-else-if="item.image_album != null" class="listImgchatshow">
                                                    <span v-for="(item2, index2) in item.image_album.split(';')" :key="index2">
                                                        <Fancybox>
                                                            <a data-fancybox="" :href="link_webchat + item2">
                                                                <img :src="link_webchat + item2" alt="">
                                                            </a>
                                                        </Fancybox>
                                                    </span>
                                                </p>
                                                <p v-else>{{ item.content }}</p>
                                                <span>{{ item.time + ' ' + item.date }}</span>
                                            </div>
                                            <div class="avartaId">
                                                <img :src="link_web + item.avatar" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_chat_mes" v-if="idId != 0">
                            <div class="replyMes_show" v-if="typeSend=='reply'">
                                <div class="repMes">
                                    <p class="titlerep">Trả lời {{ namerl }}</p>
                                    <p v-if="imgrl != null" class="imgrepshow"><img :src="link_webchat + imgrl" alt=""></p>
                                    <p v-else-if="filerl != null">{{ filerl }}</p>
                                    <p v-else>{{ contentrl }}</p>
                                </div>
                                <img src="../assets/images/closegf.png" alt="" class="imgclose_reply" @click="closeReply">
                            </div>
                            <form action="" method="post" @submit="sendChatMes">
                                <div class="wp_img_text_mes_chat">
                                    <div class="pic_file_mes">
                                        <div class="picchat_div">
                                            <input type="file" id="imagechat" accept="image/*" class="imagechat" @change="submitImgMes($event)" />
                                            <label class="lbimagechat" for="imagechat">
                                                <font-awesome-icon icon="fa-solid fa-image" />
                                            </label>
                                        </div>
                                        <div class="filechat_div">
                                            <input type="file" id="filechat" class="filechat" @change="submitFileMes($event)" />
                                            <label class="lbfilechat" for="filechat">
                                                <font-awesome-icon icon="fa-solid fa-file-pen" />
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wp_inputchat_mes">
                                        <input type="text" id="textmeschat" autocomplete="off" value="" class="inputchat_mes">
                                        <div class="stickermes">
                                            <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatMes">
                                        </div>
                                        <div class="btn_sb_mes">
                                            <button type="submit" id="submitchatMes"></button>
                                        </div>
                                    </div>
                                    <div class="liststickermes">
                                        <div class="item_icon_chat_mes">
                                            <p v-for="(item, index) in listIcon" v-bind:key="index">
                                                <img :data_img="items.resource" :src="domainIcon + items.resource" alt="" v-for="(items, indexs) in item" v-bind:key="indexs" class="icon_chat_send" @click="sendIconChatMes">
                                            </p>
                                        </div>
                                    </div>
                                    <input type="hidden" name="data_img" class="sticker_chatMes">
                                </div>
                            </form>
                        </div>
                        <div class="form_chat_mes formchat_hide" v-else>
                            <div class="replyMes_show" v-if="typeSend=='reply'">
                                <div class="repMes">
                                    <p class="titlerep">Trả lời {{ namerl }}</p>
                                    <p v-if="imgrl != null" class="imgrepshow"><img :src="link_webchat + imgrl" alt=""></p>
                                    <p v-else-if="filerl != null">{{ filerl }}</p>
                                    <p v-else>{{ contentrl }}</p>
                                </div>
                                <img src="../assets/images/closegf.png" alt="" class="imgclose_reply" @click="closeReply">
                            </div>
                            <form action="" method="post" @submit="sendChatMes">
                                <div class="wp_img_text_mes_chat">
                                    <div class="pic_file_mes">
                                        <div class="picchat_div">
                                            <input type="file" id="imagechat" class="imagechat" accept="image/*" @change="submitImgMes($event)" multiple />
                                            <label class="lbimagechat" for="imagechat">
                                                <font-awesome-icon icon="fa-solid fa-image" />
                                            </label>
                                        </div>
                                        <div class="filechat_div">
                                            <input type="file" id="filechat" class="filechat" @change="submitFileMes($event)" />
                                            <label class="lbfilechat" for="filechat">
                                                <font-awesome-icon icon="fa-solid fa-file-pen" />
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wp_inputchat_mes">
                                        <input type="text" id="textmeschat" autocomplete="off" value="" class="inputchat_mes">
                                        <div class="stickermes">
                                            <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatMes">
                                        </div>
                                        <div class="btn_sb_mes">
                                            <button type="submit" id="submitchatMes"></button>
                                        </div>
                                    </div>
                                    <div class="liststickermes">
                                        <div class="item_icon_chat_mes">
                                            <p v-for="(item, index) in listIcon" v-bind:key="index">
                                                <img :data_img="items.resource" :src="domainIcon + items.resource" alt="" v-for="(items, indexs) in item" v-bind:key="indexs" class="icon_chat_send" @click="sendIconChatMes">
                                            </p>
                                        </div>
                                    </div>
                                    <input type="hidden" name="data_img" class="sticker_chatMes">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="hidden" class="ididol_hd" value="" v-if="idId==0">
        <input type="hidden" class="ididol_hd" :value="idId" v-else>
        <input type="hidden" class="idiUser_mes" :value="idLg">
    </div>
</template>

<script>
import $ from 'jquery'
import Swal from 'sweetalert2'
// import SocketIO from 'socket.io-client'
import Fancybox from './Fancybox.vue';
$( document ).ready(function() {
    // $(document).on('click', '.item_idol_chat', function() {
    //     $('.item_idol_chat').removeClass('active');
    //     $(this).addClass('active');
    // });
    // var domainWeb = 'https://chat.f8live.click/';
    // var domainImg = 'https://f8game.click/';
    // var socket = SocketIO(domainWeb,{ transports: ['websocket', 'polling', 'flashsocket'] });
    // var img1 = require('@/assets/images/file.png')
    // var imgxoa = require('@/assets/images/xoachat.png')
    // var imgrep = require('@/assets/images/reply.png')
    // var imgfile = require('@/assets/images/file.png')
    // socket.on('chat:message',function(result){
    //     let ididol = $('.ididol_hd').val();
    //     let iduser = $('.idiUser_mes').val();
    //     let str = '';
    //     if(ididol == result.message.user_to){
    //         if(result.message.image != null){
    //             str = `<div class="item_chat_mes item_chat_mes_dele" id="itemMes${result.message.id}">
    //                         <div class="right_user_chat">
    //                             <div class="right_text_mes">
    //                                 <div class="delete_mes_right">
    //                                     <img src="${imgxoa}" alt="" class="deleChatMes" data_id="${result.message.id}">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                             <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                             if(result.message.image_reply != null){
    //                                                 str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                             }else if(result.message.file_name_reply != null){
    //                                                 str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                             }else{
    //                                                 str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                             }
    //                                             str += `</a></div>`
    //                                     }


    //                                     str += `<p><img src="${domainWeb}${result.message.image}" alt=""></p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else if(result.message.file != null){
    //             str = `<div class="item_chat_mes item_chat_mes_dele" id="itemMes${result.message.id}">
    //                         <div class="right_user_chat">
    //                             <div class="right_text_mes">
    //                                 <div class="delete_mes_right">
    //                                     <img src="${imgxoa}" alt="" class="deleChatMes" data_id="${result.message.id}">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                             <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                             if(result.message.image_reply != null){
    //                                                 str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                             }else if(result.message.file_name_reply != null){
    //                                                 str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                             }else{
    //                                                 str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                             }
    //                                             str += `</a></div>`
    //                                     }

    //                                     str += `<p><a href="${domainWeb}${result.message.file}" target="_blank"><img src="${img1}" alt="">${result.message.file_name}</a></p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else if(result.message.image_album != null){
    //             str = `<div class="item_chat_mes item_chat_mes_dele" id="itemMes${result.message.id}">
    //                         <div class="right_user_chat">
    //                             <div class="right_text_mes">
    //                                 <div class="delete_mes_right">
    //                                     <img src="${imgxoa}" alt="" class="deleChatMes" data_id="${result.message.id}">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                             <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                             if(result.message.image_reply != null){
    //                                                 str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                             }else if(result.message.file_name_reply != null){
    //                                                 str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                             }else{
    //                                                 str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                             }
    //                                             str += `</a></div>`
    //                                     }
    //                                     let imgItem = result.message.image_album.split(';')
    //                                     str += `<p class="listImgchatshow">`
    //                                                     for (let i = 0; i < imgItem.length; i++) {
    //                                                         str += `<span><img src="${domainWeb}${imgItem[i]}" alt=""></span>`
    //                                                     }
    //                                             str += `</p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else{
    //             str = `<div class="item_chat_mes item_chat_mes_dele" id="itemMes${result.message.id}">
    //                         <div class="right_user_chat">
    //                             <div class="right_text_mes">
    //                                 <div class="delete_mes_right">
    //                                     <img src="${imgxoa}" alt="" class="deleChatMes" data_id="${result.message.id}">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                                 <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                                 if(result.message.image_reply != null){
    //                                                     str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                                 }else if(result.message.file_name_reply != null){
    //                                                     str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                                 }else{
    //                                                     str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                                 }
    //                                                 str += `</a></div>`
    //                                     }

    //                                     str += `<p>${result.message.content}</p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }
    //     }else{
    //         if(result.message.image != null){
    //             str =   `<div class="item_chat_mes item_chat_mes_dele">
    //                         <div class="leftidol_chat">
    //                             <div class="left_text_mes">
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                                 <div class="textMes">`
                                        
    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                                 <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                                 if(result.message.image_reply != null){
    //                                                     str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                                 }else if(result.message.file_name_reply != null){
    //                                                     str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                                 }else{
    //                                                     str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                                 }
    //                                                 str += `</a></div>`
    //                                     }

    //                                     str+= `<p><img src="${domainWeb}${result.message.image}" alt=""></p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="delete_mes_left">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else if(result.message.file != null){
    //             str = `<div class="item_chat_mes item_chat_mes_dele">
    //                         <div class="leftidol_chat">
    //                             <div class="left_text_mes">
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                                 <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                                 if(result.message.image_reply != null){
    //                                                     str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                                 }else if(result.message.file_name_reply != null){
    //                                                     str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                                 }else{
    //                                                     str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                                 }
    //                                                 str += `</a></div>`
    //                                     }

    //                                     str += `<p><a href="${domainWeb}${result.message.file}" target="_blank"><img src="${img1}" alt="">${result.message.file_name}</a></p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="delete_mes_left">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else if(result.message.image_album != null){
    //             str = `<div class="item_chat_mes item_chat_mes_dele">
    //                         <div class="leftidol_chat">
    //                             <div class="left_text_mes">
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                                 <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                                 if(result.message.image_reply != null){
    //                                                     str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                                 }else if(result.message.file_name_reply != null){
    //                                                     str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                                 }else{
    //                                                     str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                                 }
    //                                                 str += `</a></div>`
    //                                     }
    //                                     let imgItem = result.message.image_album.split(';')
    //                                     str += `<p class="listImgchatshow">`
    //                                                 for (let i = 0; i < imgItem.length; i++) {
    //                                                     str += `<span><img src="${domainWeb}${imgItem[i]}" alt=""></span>`
    //                                                 }
    //                                             str += `</p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="delete_mes_left">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }else{
    //             str = `<div class="item_chat_mes item_chat_mes_dele">
    //                         <div class="leftidol_chat">
    //                             <div class="left_text_mes">
    //                                 <div class="avartaId">
    //                                     <img src="${domainImg}${result.message.avatar}" alt="">
    //                                 </div>
    //                                 <div class="textMes">`

    //                                     if(result.message.author_reply != null){
    //                                         str += `<div class="reply">
    //                                             <a data_id="${result.message.id_reply}" class="clickreplyrun_ss">
    //                                                 <p class="authorrl" data_id="${result.message.id_reply}">${result.message.author_reply}</p>`
    //                                                 if(result.message.image_reply != null){
    //                                                     str += `<p class="imgshowrep" data_id="${result.message.id_reply}"><img src="${domainWeb}${result.message.image_reply}" alt="" data_id="${result.message.id_reply}"></p>`
    //                                                 }else if(result.message.file_name_reply != null){
    //                                                     str += `<p data_id="${result.message.id_reply}"><img src="${imgfile}" alt="" data_id="${result.message.id_reply}">${result.message.file_name_reply}</p>`
    //                                                 }else{
    //                                                     str += `<p class="pcontent-rep" data_id="${result.message.id_reply}">${result.message.content_reply}</p>`
    //                                                 }
    //                                                 str += `</a></div>`
    //                                     }

    //                                     str += `<p>${result.message.content}</p>
    //                                     <span>${result.message.time}</span>
    //                                 </div>
    //                                 <div class="delete_mes_left">
    //                                     <img src="${imgrep}" alt="" data_id="${result.message.id}" data_name="${result.message.author}" data_content="${result.message.content}" data_img="${result.message.image}" data_filename="${result.message.file_name}" class="replybtn">
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>`
    //         }
    //     }
    //     if((ididol == result.message.user_to || ididol == result.message.user_from) && (iduser == result.message.user_to || iduser == result.message.user_from)){
    //         $(`.list_chat_detail_mes`).append(str);
    //         var hg = $('.list_chat_detail_mes').prop('scrollHeight');
    //         $('.list_chat_detail_mes').animate({ scrollTop: hg+100 },500);
    //     }
    // });
    // socket.on('chat:delete',function(result){
    //     $(`#itemMes${result.id}`).remove()
    // })
    // $(document).on('click', '.deleChatMes', function() {
    //     let idmes = $(this).attr('data_id');
    //     let tokenchat = window.localStorage.getItem('token1')
    //     $.ajax({
    //         method: 'POST',
    //         url: 'https://chat.f8live.click/api/delete-message',
    //         headers: {
    //             'Authorization':'Bearer ' + tokenchat,
    //         },
    //         data:{
    //             id: idmes,
    //         }
    //     });
    // });

    // $(document).on('click', '.clickreplyrun_ss', function() {
    //     let id = $(this).attr('data_id')
    //     let div = $(this).parents('.list_chat_detail_mes');
    //     if (typeof div.find("#itemMes"+id).offset() !== 'undefined' ) {
    //         div.animate({scrollTop: div.scrollTop() + (div.find("#itemMes"+id).offset().top - div.offset().top - 120)}, 300);
    //     }
    // });

    // socket.on('chat:pin_message',function(result){
    //     // console.log('result', result);
    //     let ididol = $('.ididol_hd').val();
    //     let iduser = $('.idiUser_mes').val();
    //     if(result.message.pin == 1){
    //         let strpin = '';
    //         strpin = `<div class="contentPin">
    //                     <div class="textpin clickRunPin" data_id="${result.message.id}">
    //                         <h4>Pin message ${result.message.author}</h4>`
    //                         if(result.message.image != null){
    //                             strpin += `<p class="imgpinchat"><img src="${domainWeb}${result.message.image}" alt=""></p>`
    //                         }else if(result.message.file != null){
    //                             strpin += `<p ><img src="${imgfile}" alt="">${result.message.file_name}</p>`
    //                         }else if(result.message.image_album != null) {
    //                             // 
    //                         }else{
    //                             strpin += `<p v-else>${result.message.content}</p>`
    //                         }
                            
    //                     strpin += `</div>
    //                     <div class="delePin">
    //                         <img src="${imgxoa}" alt="" data_id="${result.message.id}" class="deletePinNew">
    //                     </div>
    //                 </div>`
    //         if((ididol == result.message.user_to || ididol == result.message.user_from) && (iduser == result.message.user_to || iduser == result.message.user_from)){
    //             $(`.pinMessage`).html(strpin);
    //         }
    //     }
    // })

    // $(document).on('click', '.clickRunPin', function() {
    //     let id = $(this).attr('data_id')
    //     let div = $('.list_chat_detail_mes');
    //     if (typeof div.find("#itemMes"+id).offset() !== 'undefined' ) {
    //         div.animate({scrollTop: div.scrollTop() + (div.find("#itemMes"+id).offset().top - div.offset().top - 120)}, 300);
    //     }
    // });

    // $(document).on('click', '.deletePinNew', function() {
    //     $(`.contentPin`).remove()
    //     let idmes = $(this).attr('data_id');
    //     let tokenchat = window.localStorage.getItem('token1')
    //     $.ajax({
    //         method: 'POST',
    //         url: 'https://chat.f8live.click/api/delete-pin-message',
    //         headers: {
    //             'Authorization':'Bearer ' + tokenchat,
    //         },
    //         data:{
    //             id: idmes,
    //         }
    //     });
    // });

    // $(document).on('click', '.menushowmb', function() {
    //     $(this).parent().addClass('show_menu')
    //     $('.wp_left_list_mes').addClass('showidol_mobile')
    // });

    // $(document).on('click', '.menuhidemb', function() {
    //     $(this).parent().removeClass('show_menu')
    //     $('.wp_left_list_mes').removeClass('showidol_mobile')
    // });

    // $(document).on('click', '.typeMes', function() {
    //     $('.wp_left_list_mes').addClass('showidol_mobile')
    //     $('.menuidolchat_mb').addClass('show_menu')
    // });
})

export default {
    name: 'Chat_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            token1: window.localStorage.getItem('token1'),
            iduser: window.localStorage.getItem('numberus'),
            listIdolchat: [],
            idId: this.$route.params.idIdol,
            idLg: this.$route.params.idUser,
            dateEnd: '',
            listType: [],
            iduserPost: [],
            listDetailMes: [],
            nameId: '',
            picId: '',
            idIdol: '',
            listIcon: [],
            idMesEnd: '',
            typesticker: '',
            other: '',
            nameidol: '',
            imgIdol: '',
            itemData: [],
            typeSend: 'insert',
            namerl: '',
            contentrl: '',
            imgrl: '',
            filerl: '',
            idreply: '',
            listPin: [],
            checkpin: 0,
            logoWb: ''
        }
    },
    components: {
        Fancybox
    },
    created () {
        this.listidolChat()
        this.getDataType()
        this.getDataDetailMes()
        this.listIconChat()
        if(this.idId != 0){
            this.getInfoIdol()
        }
        this.addClassBody()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink + '?domain=' + this.url_param).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        scrollReply (id) {
            let divParent = this.$refs.scrollMess // div tổng
            let divChild = this.$refs['itemMes'+id]
            if (typeof divChild !== 'undefined' ) {
                divParent.scrollTo({
                    top: (divChild[0].offsetTop - divParent.offsetTop),
                    behavior: 'smooth'
                })
            }else{
                let user_to = $('.ididol_hd').val();
                this.axios.get(this.api_detailMes + '?user_to=' + user_to + '&id=' + id + '&type_message=load',
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    let dataAr = response.data.data.data.reverse()
                    if(dataAr.length > 0) {
                        this.listDetailMes = dataAr.concat(this.listDetailMes)
                        this.idMesEnd = this.listDetailMes[0].id
                        setTimeout(() => {
                            let divChilds = this.$refs['itemMes'+id]
                            if (typeof divChilds !== 'undefined' ) {
                                divParent.scrollTo({
                                    top: (divChilds[0].offsetTop - divParent.offsetTop),
                                    behavior: 'smooth'
                                }) 
                            }
                        }, 500);
                    }
                })
            }
        },
        scrollPin (id) {
            let divParent = this.$refs.scrollMess // div tổng
            let divChild = this.$refs['itemMes'+id]
            if (typeof divChild !== 'undefined' ) {
                divParent.scrollTo({
                    top: (divChild[0].offsetTop - divParent.offsetTop),
                    behavior: 'smooth'
                })
            }else{
                let user_to = $('.ididol_hd').val();
                this.axios.get(this.api_detailMes + '?user_to=' + user_to + '&id=' + id + '&type_message=load',
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    let dataAr = response.data.data.data.reverse()
                    if(dataAr.length > 0) {
                        this.listDetailMes = dataAr.concat(this.listDetailMes)
                        this.idMesEnd = this.listDetailMes[0].id
                        setTimeout(() => {
                            let divChilds = this.$refs['itemMes'+id]
                            if (typeof divChilds !== 'undefined' ) {
                                divParent.scrollTo({
                                    top: (divChilds[0].offsetTop - divParent.offsetTop),
                                    behavior: 'smooth'
                                }) 
                            }
                        }, 500);
                    }
                })
            }
        },
        scrollData (e) {
            this.$nextTick(function() {
                var hg = this.$refs.heighScroll;
                let totalHeight = hg.scrollHeight
                let heightScreen = e?.target.offsetHeight
                let heightScroll = hg.scrollTop
                if(heightScreen + heightScroll + 10 > totalHeight){
                    this.axios.get(this.api_listidolmes + '?date=' + this.dateEnd,
                        {
                            headers: {
                                Authorization: 'Bearer ' + this.token
                            }
                        }
                    ).then((response) => {
                        let dataArr = response.data.data
                        this.listIdolchat = this.listIdolchat.concat(dataArr)
                        this.dateEnd = this.listIdolchat[this.listIdolchat.length - 1].m_created_at
                    })
                }
            });
        },
        listidolChat () {
            if(this.idId != 0){
                this.axios.get(this.api_listidolmes + '?user_id=' + this.idId,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    this.listIdolchat = response.data.data
                    this.dateEnd = this.listIdolchat[this.listIdolchat.length - 1].m_created_at
                })
            }else{
                this.axios.get(this.api_listidolmes,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    this.listIdolchat = response.data.data
                    this.dateEnd = this.listIdolchat[this.listIdolchat.length - 1]?.m_created_at
                })
            }
        },
        getDataType () {
            this.axios.get(this.api_listType,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                    }
                }
            ).then((response) => {
                this.listType = response.data.data
            })
        },
        clickTypeMes (event) {
            event.preventDefault()
            let idpl = event.target.getAttribute('data_id_pl')
            let usId = event.target.getAttribute('data_id_user')
            this.iduserPost = usId.split().map(s => s.trim());
            const form = new FormData();
            form.append('id', idpl);
            this.iduserPost.map((item, index) => form.append(`arr_user[${index}]`, item));
            this.axios.post(this.api_addType, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                    }
                }
            ).then((response) => {
                this.listidolChat()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        DeleteTypeMes (event) {
            event.preventDefault()
            let usId = event.target.getAttribute('data_id_user')
            this.axios.post(this.api_deleteType, 
                {
                    user_id: usId
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                    }
                }
            ).then((response) => {
                this.listidolChat()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            })
        },
        getDataDetailMes () {
            if(this.idId != 0){
                this.axios.get(this.api_detailMes + '?user_to=' + this.idId,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    if(response.data.data.pin != null){
                        this.checkpin = 1
                        this.listPin = response.data.data.pin
                    }else{
                        this.checkpin = 0
                        this.listPin = []
                    }
                    if (response.data.data.data.length > 0) {
                        this.listDetailMes = response.data.data.data.reverse()
                        this.idMesEnd = this.listDetailMes[0].id
                    }else{
                        this.listDetailMes = ''
                    }
                    setTimeout(() => {
                        var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                        $('.list_chat_detail_mes').animate({ scrollTop: hg+100+'px' }, 5);
                    }, 1500);
                })
            }
        },
        scrollDataDetailMes () {
            this.$nextTick(function() {
                var hg = this.$refs.scrollMess;
                let heightScroll = hg.scrollTop
                let idid = $('.ididol_hd').val()
                if (heightScroll == 0 ) {
                    this.axios.get(this.api_detailMes + '?user_to=' + idid + '&type_message=top&id=' + this.idMesEnd,
                        {
                            headers: {
                                Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                            }
                        }
                    ).then((response) => {
                        let dataAr = response.data.data.data.reverse()
                        if(dataAr.length > 0) {
                            this.listDetailMes = dataAr.concat(this.listDetailMes)
                            this.idMesEnd = this.listDetailMes[0].id
                        }
                    })
                }
            });
        },
        clickMesDetail (event) {
            // const isMobile = /Mobi/i.test(navigator.userAgent)
            $('.wp_left_list_mes').removeClass('showidol_mobile')
            $('.menuidolchat_mb').removeClass('show_menu')
            if(!$(this).hasClass('active')){
                $('.item_chat_mes_dele').remove()
                $('.formchat_hide').show()
                $('.info_idol_chatnoneid').show()
                $('.pinMessage').show()
                this.idIdol = event.target.getAttribute('data_id_idol')
                $('.ididol_hd').val(this.idIdol)
                this.nameId = event.target.getAttribute('data_name_idol')
                this.picId = event.target.getAttribute('data_pic_idol')
                this.axios.get(this.api_detailMes + '?user_to=' + this.idIdol,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                        }
                    }
                ).then((response) => {
                    if(response.data.data.pin != null){
                        this.checkpin = 1
                        this.listPin = response.data.data.pin
                    }else{
                        this.checkpin = 0
                        this.listPin = []
                    }
                    if(response.data.data.data.length > 0) {
                        this.listDetailMes = response.data.data.data.reverse()
                        this.idMesEnd = this.listDetailMes[0].id
                    }else{
                        this.listDetailMes = []
                        this.idMesEnd = ''
                    }
                })
                setTimeout(() => {
                    var hg = $('.list_chat_detail_mes').prop('scrollHeight');
                    $('.list_chat_detail_mes').animate({ scrollTop: hg+100+'px' }, 5);
                }, 1500);
            }
        },
        listIconChat (){
            this.axios.get(this.api_listicon).then((response) => {
                this.listIcon = response.data.data
            })
        },
        showIconChatMes () {
            $('.liststickermes').slideToggle();
        },
        sendChatMes (e) {
            e.preventDefault();
            let mes = $('#textmeschat').val();
            let imgSticker = $('.sticker_chatMes').val()
            let idTo = $('.ididol_hd').val()

            var curDate = new Date();
            var hours = curDate.getHours();
            var minutes = curDate.getMinutes();
            let minutess = ''
            if(minutes < 10){
                minutess = '0' + minutes;
            }else{
                minutess = minutes
            }
            const ampm = hours >= 12 ? "PM" : "AM";
            // let gio = hours % 12;
            // gio = gio ? gio : 12;
            let hourMinutes = hours + ':' + minutess + ' ' + ampm
            this.itemData = this.listIdolchat.filter(d=>d.id == idTo)
            this.itemData.filter(item => item.id == idTo).map(function (obj) {
                obj.content = mes;
                obj.m_time = hourMinutes;
                return obj;
            });

            const indexDeleteVideo = this.listIdolchat.findIndex(
                value => value.id == idTo,
            );
            this.listIdolchat.splice(indexDeleteVideo, 1);
            this.listIdolchat = this.itemData.concat(this.listIdolchat)
            setTimeout(() => {
                $('.item_idol_chat').removeClass('active')
                $('#itemidolchatLeft'+idTo).addClass('active')
            }, 300);
            

            if(imgSticker != ''){
                this.typesticker = 1
            }else{
                this.typesticker = ''
            }
            const form = new FormData();
            form.append('message', mes);
            form.append('upload_image_message', '');
            form.append('upload_image_album_message', '');
            form.append('content_image_album_message', '');
            form.append('upload_file_message', '');
            form.append('image_sticker', imgSticker);
            form.append('type_sticker', this.typesticker);
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);
            if(this.typeSend == 'reply'){
                form.append('id_edit', this.idreply);
            }
            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                $('#textmeschat').val('');
                $('.sticker_chatMes').val('');
                this.typeSend = 'insert'
            }).catch(e => {
                Swal.fire({
                    position: 'top-end',
                    showConfirmButton: false,
                    title: e.response.data.message,
                    icon: 'error',
                    timer: 2000
                });
            })
            return false;
        },
        sendIconChatMes (event) {
            var dataImg = event.target.getAttribute('data_img')

            let idTo = $('.ididol_hd').val()
            var curDate = new Date();
            var hours = curDate.getHours();
            var minutes = curDate.getMinutes();
            let minutess = ''
            if(minutes < 10){
                minutess = '0' + minutes;
            }else{
                minutess = minutes
            }
            const ampm = hours >= 12 ? "PM" : "AM";
            let hourMinutes = hours + ':' + minutess + ' ' + ampm
            this.itemData = this.listIdolchat.filter(d=>d.id == idTo)
            this.itemData.filter(item => item.id == idTo).map(function (obj) {
                obj.m_time = hourMinutes;
                return obj;
            });

            $('.sticker_chatMes').val(dataImg)
            $('#submitchatMes').trigger('click')
            $('.liststickermes').hide()
        },
        submitImgMes (event) {
            event.preventDefault();
            let imgLength = document.getElementById('imagechat').files.length
            let url_image = document.getElementById('imagechat').files[0]
            let arrImg = document.getElementById('imagechat').files
            let idTo = $('.ididol_hd').val()

            var curDate = new Date();
            var hours = curDate.getHours();
            var minutes = curDate.getMinutes();
            let minutess = ''
            if(minutes < 10){
                minutess = '0' + minutes;
            }else{
                minutess = minutes
            }
            const ampm = hours >= 12 ? "PM" : "AM";
            let hourMinutes = hours + ':' + minutess + ' ' + ampm
            this.itemData = this.listIdolchat.filter(d=>d.id == idTo)
            this.itemData.filter(item => item.id == idTo).map(function (obj) {
                obj.m_time = hourMinutes;
                return obj;
            });
            const indexDeleteVideo = this.listIdolchat.findIndex(
                value => value.id == idTo,
            );
            this.listIdolchat.splice(indexDeleteVideo, 1);
            this.listIdolchat = this.itemData.concat(this.listIdolchat)
            setTimeout(() => {
                $('.item_idol_chat').removeClass('active')
                $('#itemidolchatLeft'+idTo).addClass('active')
            }, 300);
            const form = new FormData();
            form.append('message', '');
            if(imgLength==1){
                form.append('upload_image_message', url_image);
                form.append('upload_image_album_message', '');
            }else{
                form.append('upload_image_message', '');
                arrImg.forEach(item => {
                    form.append('upload_image_album_message[]', item);
                });
            }
            form.append('content_image_album_message', '');
            form.append('upload_file_message', '');
            form.append('image_sticker', '');
            form.append('type_sticker', '');
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);

            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                $("#imagechat").val('');
                this.typeSend = 'insert'
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        submitFileMes (event) {
            event.preventDefault();
            let url_file = document.getElementById('filechat').files[0]
            let idTo = $('.ididol_hd').val()

            var curDate = new Date();
            var hours = curDate.getHours();
            var minutes = curDate.getMinutes();
            let minutess = ''
            if(minutes < 10){
                minutess = '0' + minutes;
            }else{
                minutess = minutes
            }
            const ampm = hours >= 12 ? "PM" : "AM";
            let hourMinutes = hours + ':' + minutess + ' ' + ampm
            this.itemData = this.listIdolchat.filter(d=>d.id == idTo)
            this.itemData.filter(item => item.id == idTo).map(function (obj) {
                obj.m_time = hourMinutes;
                return obj;
            });
            const indexDeleteVideo = this.listIdolchat.findIndex(
                value => value.id == idTo,
            );
            this.listIdolchat.splice(indexDeleteVideo, 1);
            this.listIdolchat = this.itemData.concat(this.listIdolchat)
            setTimeout(() => {
                $('.item_idol_chat').removeClass('active')
                $('#itemidolchatLeft'+idTo).addClass('active')
            }, 300);

            const form = new FormData();
            form.append('message', '');
            form.append('upload_image_message', '');
            form.append('upload_image_album_message', '');
            form.append('content_image_album_message', '');
            form.append('upload_file_message', url_file);
            form.append('image_sticker', '');
            form.append('type_sticker', '');
            form.append('user_from', idTo);
            form.append('type_action', this.typeSend);

            this.axios.post(this.api_sendMes, 
                form,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                $("#filechat").val('');
                this.typeSend = 'insert'
            }).catch(e => {
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 3000
                })
            })
            return false;
        },
        getInfoIdol () {
            this.axios.get(this.api_infidol + '?id=' + this.idId).then((response) => {
                this.nameidol = response.data.data.user.fullname
                this.imgIdol = response.data.data.user.hinh_anh
            })
        },
        searchMes (e) {
            e.preventDefault()
            let keyname = $('.key_search').val().toLowerCase()
            this.axios.get(this.api_listidolmes + '?key=' + keyname,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                    }
                }
            ).then((response) => {
                this.listIdolchat = response.data.data
                this.dateEnd = this.listIdolchat[this.listIdolchat.length - 1].m_created_at
            })
        },
        deleteMes (event) {
            let idMes = event.target.getAttribute('data_id')
            $('#itemMes'+idMes).remove()
            this.axios.post(this.api_deleteMes,
                {
                    id: idMes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token1')
                    }
                }
            ).then((response) => {
                this.other = response
            })
        },
        repyChat (event) {
            this.typeSend = 'reply'
            this.idreply = event.target.getAttribute('data_id')
            this.namerl = event.target.getAttribute('data_name')
            this.contentrl = event.target.getAttribute('data_content')
            this.imgrl = event.target.getAttribute('data_img')
            this.filerl = event.target.getAttribute('data_filename')
        },
        closeReply () {
            this.typeSend = 'insert'
        },
        pinMes (event) {
            event.preventDefault();
            var idmes = event.target.getAttribute('data_id')
            this.axios.post(this.api_pinMes, 
                {
                    id: idmes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
            }).catch(e => {
                this.other = e
            })
            return false;
        },
        deletePinMes (event) {
            event.preventDefault();
            var idmes = event.target.getAttribute('data_id')
            this.axios.post(this.api_delepinMes, 
                {
                    id: idmes
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token1
                    }
                }
            ).then((response) => {
                this.other = response
                this.checkpin = 0
            }).catch(e => {
                this.other = e
            })
            return false;
        },
        addClassBody () {
            $('body').addClass('bottomn_none')
        },
    },
    beforeUnmount () {
        $('body').removeClass('bottomn_none')
    }
}
</script>
<style>
</style>
