import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue3-cookies'

import Vue3VideoPlayer from '@cloudgeek/vue3-video-player';
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css';

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import "vue3-paginate-z/dist/styles.css";
import Paginate from "vue3-paginate-z";

import Vue3DraggableResizable from 'vue3-draggable-resizable'
import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import CKEditor from '@ckeditor/ckeditor5-vue'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import Sticky from 'vue3-sticky-directive'

import NumericInput from 'vue3-numeric-input';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Vue3Marquee from 'vue3-marquee'

import { faUsers, faCaretRight, faVideo, faFish, faExplosion, faGamepad, faPerson, faUser, faHeadset, faPowerOff, faUpload, faPen, faCamera, faImage, faCodeCompare, faChartSimple, faPenToSquare, faTrashCan, faFilePen } from '@fortawesome/free-solid-svg-icons'

library.add(faUsers, faCaretRight, faFish, faVideo, faExplosion, faGamepad, faPerson, faUser, faHeadset, faPowerOff, faUpload, faPen, faCamera, faImage, faCodeCompare, faChartSimple, faPenToSquare, faTrashCan, faFilePen)

const app = createApp(App)

var domain_web = 'https://adm.tolive.fun/api/'

app.config.globalProperties.url_param = 'tolive.fun'
app.config.globalProperties.link_web = 'https://adm.tolive.fun/'
app.config.globalProperties.link_webadm = 'https://adm.king88life.net/'
app.config.globalProperties.link_webs = 'https://tolive.fun/'
app.config.globalProperties.link_banner = 'https://www.f8bety.com/register?a=1794223'
app.config.globalProperties.link_forgot = 'https://www.f8019.com/Login'
app.config.globalProperties.api_login = domain_web + 'login'
app.config.globalProperties.api_logout = domain_web + 'logout'
app.config.globalProperties.api_register = domain_web + 'register'
app.config.globalProperties.api_changepass = domain_web + 'change-password'
app.config.globalProperties.api_forgot = domain_web + 'reset-password'
app.config.globalProperties.api_infouser = domain_web + 'info-user'
app.config.globalProperties.api_infidol = domain_web + 'idol-by-id'
app.config.globalProperties.api_live = domain_web + 'livestream'
app.config.globalProperties.api_change_avarta = domain_web + 'change-avatar'
app.config.globalProperties.api_change_bgr = domain_web + 'change-background'
app.config.globalProperties.api_listlive = domain_web + 'list-idol-live'
app.config.globalProperties.api_resettoken = domain_web + 'refresh'
app.config.globalProperties.api_view = domain_web + 'view'
app.config.globalProperties.api_view_live = domain_web + 'view-live'
app.config.globalProperties.api_listidol18 = domain_web + 'list-idol-live-vip'
app.config.globalProperties.api_listidol18lg = domain_web + 'list-idol-live-vip-guest'
app.config.globalProperties.api_listBxh = domain_web + 'rank-idol-point'
app.config.globalProperties.api_rankfollow = domain_web + 'rank-idol-follow'
app.config.globalProperties.api_listall = domain_web + 'list-idol-f8'

app.config.globalProperties.api_uploads_video = domain_web + 'upload-video'
app.config.globalProperties.api_delete_video = domain_web + 'delete-video'
app.config.globalProperties.api_list_video_up = domain_web + 'list-video'
app.config.globalProperties.api_view_video = domain_web + 'view-video'

app.config.globalProperties.api_messagepin = domain_web + 'pin-message'
app.config.globalProperties.api_updateMess = domain_web + 'update-pin-message'

app.config.globalProperties.api_liststicker = domain_web + 'list-sticker'
app.config.globalProperties.api_liststickerlg = domain_web + 'list-sticker-guest'
app.config.globalProperties.api_sendMessage = domain_web + 'chat/send-message'
app.config.globalProperties.api_sendMessagelg = domain_web + 'send-message'
app.config.globalProperties.api_follow = domain_web + 'follow'
app.config.globalProperties.api_unfollow = domain_web + 'unfollow'
app.config.globalProperties.api_changename = domain_web + 'change-name'
app.config.globalProperties.api_offstream = domain_web + 'off-stream'
app.config.globalProperties.api_preview = domain_web + 'change-introduce-mobile'
app.config.globalProperties.api_listtopic = domain_web + 'list-topic'
app.config.globalProperties.api_sendSupport = domain_web + 'chat/send-support'
app.config.globalProperties.api_listchatsp = domain_web + 'chat/list-support'
app.config.globalProperties.api_spDetail = domain_web + 'chat/list-support-detail'
app.config.globalProperties.api_spDelete = domain_web + 'chat/delete-support'
app.config.globalProperties.api_listFaq = domain_web + 'list-faq'
app.config.globalProperties.api_previewLink = domain_web + 'link'
app.config.globalProperties.api_giftSticker = domain_web + 'history-sticker'
app.config.globalProperties.api_giftStickerGift = domain_web + 'history-sticker-gift'
app.config.globalProperties.api_iconGifft = domain_web + 'list-sticker-gift'
app.config.globalProperties.api_listChart = domain_web + 'rank-idol-point'
app.config.globalProperties.api_updateInfo = domain_web + 'update-info'
app.config.globalProperties.api_getSalary = domain_web + 'get-salary'
app.config.globalProperties.api_debitSalary = domain_web + 'debit-salary'
app.config.globalProperties.api_listIdolAgency = domain_web + 'list-idol-agency'
app.config.globalProperties.api_dataEvent = domain_web + 'list-event'

// support not login
app.config.globalProperties.api_sendSupportNologin = domain_web + 'send-support'
app.config.globalProperties.api_listchatspNologin = domain_web + 'list-support'
app.config.globalProperties.api_spDetailNologin = domain_web + 'list-support-detail'
app.config.globalProperties.api_spDeleteNologin = domain_web + 'delete-support'
app.config.globalProperties.api_listSpDetailNologin = domain_web + 'support-detail'

app.config.globalProperties.link_advs = 'https://www.f8019.com/?a=1794224'
app.config.globalProperties.api_listmatchdate = 'https://vaohang.tech/api/danh-sach/tat_ca?sort=day'
app.config.globalProperties.api_listmatchdates = 'https://vaohang.tech/api/danh-sach/tat_ca'
app.config.globalProperties.api_listvideo = 'https://f8game.info/items/read.php'
app.config.globalProperties.linkvideo = 'https://videof8bet.com/video/'
app.config.globalProperties.api_listvideoidol = 'https://f8game.pro/public/items/listVideo.php'
app.config.globalProperties.api_listvideoUs = 'https://f8game.pro/public/items/listVideobyUser.php'

app.config.globalProperties.api_listGift = domain_web + 'list-gift'
app.config.globalProperties.api_listGiftIdol = domain_web + 'list-gift-idol'
app.config.globalProperties.api_giveGift = domain_web + 'give-gift'
app.config.globalProperties.api_viewLive = domain_web + 'view-real'
app.config.globalProperties.api_takeGift = domain_web + 'receive-gift-guest'
app.config.globalProperties.api_takeGiftLogin = domain_web + 'receive-gift'
app.config.globalProperties.api_postGa = domain_web + 'analytics-detail'
app.config.globalProperties.api_listNoti = domain_web + 'list-notification'
app.config.globalProperties.api_viewNoti = domain_web + 'view-notifi-res'
app.config.globalProperties.api_listBank = domain_web + 'list-bank'

app.config.globalProperties.link_facebook = 'https://www.facebook.com/F8gamelive/'
app.config.globalProperties.link_tele = 'https://t.me/CSKHF8BET68'

// api group chat
app.config.globalProperties.api_listGroup = domain_web + 'chat-idol/list-group'
app.config.globalProperties.api_listGroupid = domain_web + 'chat-idol/list-channel'
app.config.globalProperties.api_listGroupidMember = domain_web + 'chat-idol/list-channel-idol'
app.config.globalProperties.api_createdGroup = domain_web + 'chat-idol/add-group'
app.config.globalProperties.api_editGroup = domain_web + 'chat-idol/update-group'
app.config.globalProperties.api_deleteGroup = domain_web + 'chat-idol/delete-group'
app.config.globalProperties.api_joinGroup = domain_web + 'chat-idol/join-group'
app.config.globalProperties.api_exitGroupt = domain_web + 'chat-idol/exit-group'
app.config.globalProperties.api_clickExitGroupt = domain_web + 'chat-idol/ban-member-group'
app.config.globalProperties.api_listMember = domain_web + 'chat-idol/list-group-member'
app.config.globalProperties.api_listMesGroup = domain_web + 'chat-idol/list-group-detail'
app.config.globalProperties.api_chatIdolGroup = domain_web + 'chat-idol/send-message'
app.config.globalProperties.api_deleteChatGroup = domain_web + 'chat-idol/delete-message'
app.config.globalProperties.api_checkMemberGroup = domain_web + 'chat-idol/check-group'

app.config.globalProperties.api_cashin = domain_web + 'credit-cash'
app.config.globalProperties.api_cashout = domain_web + 'debit-cash'
app.config.globalProperties.api_historycash = domain_web + 'history-cash'
app.config.globalProperties.api_ratiocash = domain_web + 'ratio-cash'
app.config.globalProperties.api_historyFollow = domain_web + 'history-user-follow'

// app.config.globalProperties.api_live18_dexuat = 'https://api.f8game.info'
// app.config.globalProperties.api_live18_dexuat = 'https://stripchat.com/api/external/v4/widget/'
app.config.globalProperties.api_live18_dexuat = domain_web + 'propose'
app.config.globalProperties.api_listBG = domain_web + 'bigo'
app.config.globalProperties.api_GameBG = domain_web + 'bigo-game'

app.config.globalProperties.api_ListGameBG = 'https://ta.bigo.tv/official_website/OInterfaceWeb/vedioList/11?tabType=00&fetchNum=50&lang=vi'
app.config.globalProperties.api_DetailGameBG = 'https://ta.bigo.tv/official_website/studio/getInternalStudioInfo'

app.config.globalProperties.domain_str = 'https://edge-hls.doppiocdn.net/hls/'
app.config.globalProperties.domain_strs = 'https://edge-hls.doppiocdn.com/hls/'

app.config.globalProperties.api_chatdexuat = domain_web + 'chat/suggest-message'
app.config.globalProperties.api_sendchatdexuat = domain_web + 'chat/send-suggest-message'

app.config.globalProperties.api_timeview = domain_web + 'view-propose'
app.config.globalProperties.api_timeviewnolg = domain_web + 'view-propose-guest'
app.config.globalProperties.api_followidol = domain_web + 'follow-idol-propose'
app.config.globalProperties.api_listfollow = domain_web + 'list-follow-idol'
app.config.globalProperties.api_listvideof8bet = 'https://api.videof8bet.com/api/video/getPagingV2?pageSize=50'
app.config.globalProperties.codeParam = 'LIVEB109'

app.config.globalProperties.api_listAsian = domain_web + 'asia'
app.config.globalProperties.api_ckfollow = domain_web + 'check-follow-idol-propose'
app.config.globalProperties.api_registerIdol = domain_web + 'register-idol'
app.config.globalProperties.api_posttext = domain_web + 'list-post'
app.config.globalProperties.api_listRegister = domain_web + 'list-reg-idol'

app.config.globalProperties.api_payroll = domain_web + 'list-payroll'
app.config.globalProperties.api_timesheets = domain_web + 'list-timesheet'
app.config.globalProperties.api_penalties = domain_web + 'list-qc'
app.config.globalProperties.api_listcashout = domain_web + 'history-debit-cash'
app.config.globalProperties.api_sentOtp = domain_web + 'sent-otp'
app.config.globalProperties.api_resetPassword = domain_web + 'reset-password'
app.config.globalProperties.api_listVip = domain_web + 'list-vip'

// chat
var domain_chat = 'https://chat.f8live.click/api/'
app.config.globalProperties.link_webchat = 'https://chat.f8live.click/'
app.config.globalProperties.api_loginChat = domain_chat + 'login'
app.config.globalProperties.api_listidolmes = domain_chat + 'list-user'
app.config.globalProperties.api_listType = domain_chat + 'list-group-user'
app.config.globalProperties.api_addType = domain_chat + 'list-group-user-detail-add'
app.config.globalProperties.api_deleteType = domain_chat + 'list-group-user-detail-delete'
app.config.globalProperties.api_detailMes = domain_chat + 'detail-message'
app.config.globalProperties.api_sendMes = domain_chat + 'add-message'
app.config.globalProperties.api_deleteMes = domain_chat + 'delete-message'
app.config.globalProperties.api_pinMes = domain_chat + 'pin-message'
app.config.globalProperties.api_delepinMes = domain_chat + 'delete-pin-message'

var domain_icon = 'https://f8betchat.com/api/'
app.config.globalProperties.domainIcon = 'https://f8betchat.com/'
app.config.globalProperties.api_listicon = domain_icon + 'list-sticker'

// api post
app.config.globalProperties.api_listPost = domain_web + 'post-fe/list-post?limit=50'
app.config.globalProperties.api_CreatedPost = domain_web + 'post-fe/send-pots'

// api game
app.config.globalProperties.api_Game = 'https://adm.king88life.net/api/login'
// app.config.globalProperties.link_ios = 'https://dl.signv4.com/temp/index.php?hash=bGl2ZXNob3djb20udG9saXZlZ2FtZXNob3dhcHBsaXZl'
app.config.globalProperties.link_ios = 'https://dl.signv4.com/temp/index.php?hash=bGl2ZVNob3djb20udG9saXZlZnVua2luZ2dhbWU='

app.use(router)
app.use(VueAxios, axios)
app.use(Vue3VideoPlayer)
app.use(Paginate)
app.use(VueSweetalert2)
app.use(Vue3DraggableResizable)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(store)
app.use(CKEditor)
app.use(VueCookies);
app.use(bottomNavigationVue);
app.use(Sticky)
app.directive('numeric-input', NumericInput)
app.use(Vue3Marquee)
app.mount('#app')
