<template>
    <div id="content" class="content-margin">
        <div class="root_video_live">
            <div class="title_item_idol">
                <h3>
                    <img src="../assets/images/ddx.png" alt="">
                    <span>Live show 18+</span>
                </h3>
            </div>
            <div class="row">
                <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in datas" :key="index">
                    <div class="item_live">
                        <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], free: 0} }" @click="getLocalstoragePage">
                            <div class="img_live">
                                <img :src="item[1]" alt="">
                                <div class="hover_icon">
                                    <font-awesome-icon icon="fa-solid fa-caret-right" />
                                </div>
                            </div>
                            <div class="text_item_live">
                                <div class="img_live_small">
                                    <img :src="item[1]" alt="">
                                </div>
                                <div class="text_live_small">
                                    <h3>{{ item[2].substring(0, 10) }}</h3>
                                </div>
                                <img :src="item[3]" alt="" class="img_live_green">
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
export default {
    name: 'VideoPropose_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            listLiveidol: [],
            page: 1,
            perPage: 30,
            totalCount: 0,
            datas: [],
            name: '',
            other: ''
        }
    },
    components: {
    },
    mounted() {
        
    },
    created () {
        this.getDataliveidol()
    },
    methods: {
        getDataliveidol () {
            this.axios.get(this.api_live18_dexuat).then((response) => {
                // this.listLiveidol = response.data
                // let listLiveidol = this.shuffle(response.data.data)

                // const dataImage = [];
                // listLiveidol.forEach(item => {
                //     let check_mbut = 0
                //     this.axios.get(this.domain_str + item[0] + '/master/' + item[0] + '_480p.m3u8').then(res => {
                //         this.other = res
                //         check_mbut = 1
                //         dataImage.push({...item, check_mbut});
                //     }).catch(e => {
                //         this.other = e
                //         check_mbut = 0
                //         dataImage.push({...item, check_mbut});
                //     })
                // });
                // setTimeout(() => {
                //     this.listLiveidol = dataImage;
                //     this.totalCount = this.listLiveidol.length
                //     this.paginatedData()
                // }, 800);

                let isMobile = /Mobi/i.test(navigator.userAgent)
                if(isMobile == true){
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[4] == 200 && d[6] == 1));
                    this.totalCount = this.listLiveidol.length
                    this.paginatedData()
                }else{
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[6] == 1));
                    this.totalCount = this.listLiveidol.length
                    this.paginatedData()
                }

                // this.listLiveidol = this.shuffle(response.data.data);
                // this.totalCount = this.listLiveidol.length
                // this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listLiveidol.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        // https://stripchat.com/api/front/v2/models/username/Slave6996/cam
    }
}
</script>
<style>
</style>
