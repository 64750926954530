<template>
    <div class="frm_chat_live">
        <form method="post" action="" name="frm_chat" id="_frm_chat" @submit="sendChat">
            <div class="btn-chatbd">
                <input type="hidden" name="url-chat" id="url-chat" v-bind:value="this.$route.params.idlive">
                <input type="hidden" name="data-img" class="dataImg_chat">
                <div class="wp_content_chat">
                    <input type="text" name="content_chat" id="content_chat" class="content_chat" placeholder="Nội dung" >
                    <div class="name_chat">
                        <img src="../assets/images/icon.png" alt="" class="show_list_icon" @click="showIconChatLive">
                        <div class="list_icon_chat">
                            <div class="tabList_icon">
                                <ul>
                                    <li :class="{active: tabSticker === 'cap1'}" v-on:click="changeTabSticker('cap1')">Hot</li>
                                    <li :class="{active: tabSticker === 'cap2'}" v-on:click="changeTabSticker('cap2')">Event</li>
                                    <li :class="{active: tabSticker === 'cap3'}" v-on:click="changeTabSticker('cap3')">Sticker</li>
                                    <li :class="{active: tabSticker === 'cap4'}" v-on:click="changeTabSticker('cap4')">Happy</li>
                                    <li :class="{active: tabSticker === 'cap5'}" v-on:click="changeTabSticker('cap5')">Vip</li>
                                </ul>
                            </div>
                            <div class="item_icon_chat">
                                <div class="tabSticker_icon" v-if="tabSticker === 'cap1'">
                                    <p v-for="(item, index) in listIcon" v-bind:key="index">
                                        <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }} xu</span>
                                    </p>
                                </div>
                                <div class="tabSticker_icon" v-if="tabSticker === 'cap2'">
                                    <p v-for="(item, index) in listIcon1" v-bind:key="index">
                                        <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }} xu</span>
                                    </p>
                                </div>
                                <div class="tabSticker_icon" v-if="tabSticker === 'cap3'">
                                    <p v-for="(item, index) in listIcon2" v-bind:key="index">
                                        <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }} xu</span>
                                    </p>
                                </div>
                                <div class="tabSticker_icon" v-if="tabSticker === 'cap4'">
                                    <p v-for="(item, index) in listIcon3" v-bind:key="index">
                                        <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }} xu</span>
                                    </p>
                                </div>
                                <div class="tabSticker_icon" v-if="tabSticker === 'cap5'">
                                    <p v-for="(item, index) in listIcon4" v-bind:key="index">
                                        <img :data_img="item.id" :src="link_web + item.url" alt="" class="icon_chat_send" @click="sendIconChat">
                                        <span class="point_level" v-if="item.amount == 0" :data_img="item.id" @click="sendIconChat">{{ item.point}} điểm</span>
                                        <span class="point_level" v-else :data_img="item.id" @click="sendIconChat">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item.amount) }} xu</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn_submit_chat" id="chat-submit">Gửi</button>
                </div>
            </div>
        </form>

        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showmodal_cashin" @click="showmodal_cashin = false"></div>
            <div class="modal" v-if="showmodal_cashin">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <ul class="list_menu_modal">
                            <!-- <li class="img_modal"><img src="../assets/images/logo.png"></li> -->
                            <li class="active">Nạp tiền</li>
                        </ul>
                        <button class="close-modal_login" @click="showmodal_cashin = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div class="frm_cashimport">
                            <form action="" method="post" id="frm_uploads_img" @submit="cashinFrm">
                                <input v-numeric-input="{ thousands: ',', align: 'left'}" id="pointcash" placeholder="Số tiền (*)" />
                                <input type="file" @change="changenameFile" class="change_picture" name="cash_picture" id="cash_picture" />
                                <label class="cash_picture" for="cash_picture">
                                    <span>Chọn ảnh</span>
                                </label>
                                <textarea v-model="note" id="notecash" cols="30" rows="3" placeholder="Ghi chú"></textarea>
                                <button type="submit">Nạp tiền</button>
                                <p class="text_ratio">Nạp <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span> nhận được <span>{{ xucode }}</span> xu</p>
                                <p class="title_ratio">
                                    Nạp tối thiểu <span>{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(min_credit) }}</span>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import $ from 'jquery'
import Swal from 'sweetalert2'
import SocketIO from 'socket.io-client'
$( document ).ready(function() {
    var domainWeb = 'https://adm.tolive.fun/';
    var socket = SocketIO(domainWeb,{ transports: ['websocket', 'polling', 'flashsocket'] });
    // console.log('123', socket);
    socket.on('chat:chat_live',function(result){
        // console.log('result', result);
        let str = '';
        let str1 = '';
        let str2 = '';
        let img = require('@/assets/images/vp.gif')
        let img1 = require('@/assets/images/tn.png')
        let imgGift = require('@/assets/images/quas.gif')

        let fullnameus = window.localStorage.getItem('fullname')
        // type = 1 tin nhắn
        // type = 2 sticker
        // type = 3 thông báo vào phòng
        // type = 4 link
        // type = 0 xóa tin nhắn
        if(result.data.type==0){
            $('.msg-' + result.data.id).hide()
        }else{
            if(result.data.sticker == ''){
                if(result.data.type==3){
                    this.stickerDV = `<div class="namechat_live namechat_live_inroom">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content">${result.data.content}</span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="vaophongmq">
                                    <img src="${img}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else if(result.data.type==4){
                    this.stickerDV = `<div class="namechat_live namechat_live_inroom">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content"><a href="${result.data.content}" target="_blank">${result.data.content}</a></span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="chattextrun">
                                    <img src="${img1}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else{
                    this.stickerDV = `<div class="namechat_live">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b><span class="textchat_content">${result.data.content}</span></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left">
                                <div class="chattextrun">
                                    <img src="${img1}" alt="" />
                                    <span><b class="fullname_color">${result.data.fullname}</b> ${result.data.content}</span>
                                </div>
                            </marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }
            }else{
                if(result.data.amount == 0){
                    this.stickerDV = `<div class="namechat_live">${result.data.fullname} <b class="point_css point_css_chat point_css${result.data.level_rank}">${result.data.level_rank}</b></div><div class="imgsticker"><img src="${domainWeb}${result.data.sticker}" alt=""></div>`;
                    str1 = `<marquee width="100%" loop="1" class="mq${result.data.id}" direction="left"><img src="${domainWeb}${result.data.sticker}" alt=""></marquee>`
                    setTimeout(function(){ 
                        $(`.mq${result.data.id}`).remove()
                    }, 30000);
                }else{
                    if(result.data.type == 2){
                        this.stickerDV = `<div class="imgsticker"><img src="${domainWeb}${result.data.sticker}" alt=""><div class="nameSendsticker">Chúc mừng bạn đã được <span>${ fullnameus }</span> tặng <span>${result.data.amount} xu</span></div></div>`;
                        str2 = `<div class="animation_pointMember_wp"><div class="animation_wp">
                                    <div class="img_pointMember">
                                        <img src="${domainWeb}${result.data.sticker}" alt="">
                                    </div>
                                    <div class="name_numberpoint">
                                        <div class='hello' style='color: #0091ea'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                        <div class='hello' style='color: #ff9100'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                        <div class='hello' style='color: #e30ff0'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                    </div>
                                </div></div>`
                        setTimeout(function(){ 
                            $(`.animation_pointMember_wp`).remove()
                        }, 3000);
                    }else{
                        this.stickerDV = `<div class="imgsticker"><img src="${domainWeb}${result.data.sticker}" alt=""><div class="nameSendsticker">Chúc mừng bạn đã được <span>${ fullnameus }</span> tặng <span>${result.data.amount} xu</span></div></div>`;
                        str2 = `<div class="animation_pointMember_wp"><div class="animation_wp">
                                    <div class="img_pointMember">
                                        <img src="${domainWeb}${result.data.sticker}" alt="" class="ppicSticker_gift">
                                        <img src="${imgGift}" alt="" class="imgGift_bg">
                                    </div>
                                    <div class="name_numberpoint">
                                        <div class='hello' style='color: #0091ea'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                        <div class='hello' style='color: #ff9100'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                        <div class='hello' style='color: #e30ff0'>${result.data.fullname} <span class="giftspan">đã tặng bạn</span> <span class="tengift">${result.data.amount}</span> xu</div>
                                    </div>
                                </div></div>`
                        setTimeout(function(){ 
                            $(`.animation_pointMember_wp`).remove()
                        }, 8000);
                        setTimeout(function(){ 
                            $(`.ppicSticker_gift`).show()
                        }, 4500);
                        setTimeout(function(){ 
                            $(`.imgGift_bg`).css('opacity', '0')
                        }, 4500);
                    }
                }
            }
            str = `<div class="wp_item_chat_live msg msg-${result.data.id}">
                ${this.stickerDV}
            </div>`;
        }

        $(`.marque_div${result.data.live_id}`).append(str1);
        $(`#${result.data.live_id}`).append(str);
        $(`.animation_pointMember${result.data.live_id}`).append(str2)

        var hg = $('.chat-text').prop('scrollHeight');
        $('.chat-text').animate({ scrollTop: hg+100 },500);
    });
});
export default {
    name: 'FormChat_nm',
    data () {
        return {
            token: window.localStorage.getItem('token'),
            id_live: this.$route.params.idlive,
            listIcon: [],
            listIcon1: [],
            listIcon2: [],
            listIcon3: [],
            listIcon4: [],
            linkf8bet: '',
            tabSticker: 'cap1',
            
            showmodal_cashin: false,
            numberus: window.localStorage.getItem('numberus'),
            amount_change: '',
            point_change: '',
            min_credit: '',
            max_credit: '',
            pointcode: '',
            xucode: '',
            amount_change_debit: '',
            min_debit: '',
            max_debit: '',
            valdebit: '',
        }
    },
    components: {
    },
    created () {
        this.listIconChat()
        this.getInfoLink()
        this.getRatiocash()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
            })
        },
        showIconChatLive () {
            $('.list_icon_chat').slideToggle();
        },
        listIconChat (){
            if(this.token == null){
                this.axios.get(this.api_liststickerlg).then((response) => {
                    this.listIcon = response.data.data.data_sticker.filter(d => d.rank == 1)
                    this.listIcon1 = response.data.data.data_sticker.filter(d => d.rank == 2)
                    this.listIcon2 = response.data.data.data_sticker.filter(d => d.rank == 3)
                    this.listIcon3 = response.data.data.data_sticker.filter(d => d.rank == 4)
                    this.listIcon4 = response.data.data.data_sticker.filter(d => d.rank == 5)
                })
            }else{
                this.axios.get(this.api_liststicker, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    } 
                }).then((response) => {
                    this.listIcon = response.data.data.data_sticker.filter(d => d.rank == 1)
                    this.listIcon1 = response.data.data.data_sticker.filter(d => d.rank == 2)
                    this.listIcon2 = response.data.data.data_sticker.filter(d => d.rank == 3)
                    this.listIcon3 = response.data.data.data_sticker.filter(d => d.rank == 4)
                    this.listIcon4 = response.data.data.data_sticker.filter(d => d.rank == 5)
                })
            }
        },
        sendIconChat (event) {
            var dataImg = event.target.getAttribute('data_img')
            $('.dataImg_chat').val(dataImg)
            $('#chat-submit').trigger('click')
            $('.list_icon_chat').hide()
        },
        sendChat (e) {
            e.preventDefault();
            var content = $('#content_chat').val();
            var url_lk = $('input[name="url-chat"]').val();
            var dataimage = $('.dataImg_chat').val()
            if(dataimage != ''){
                $('.loading_show').show().fadeIn(10)
            }
            if(this.token == null){
                let ss = sessionStorage.getItem("session");
                let namess = 'Khách' + ss.substring(10, 14)
                this.axios.post(this.api_sendMessagelg, 
                    {
                        id_live: url_lk,
                        content: content,
                        sticker: dataimage,
                        name: namess,
                        session_id: ss
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#content_chat').val('');
                    $('.dataImg_chat').val('');
                    this.var_res = response
                    if(dataimage != ''){
                        $('#loading_show').css('display', 'none')
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    Swal.fire({
                        position: 'top-end',
                        showConfirmButton: false,
                        title: e.response.data.message,
                        icon: 'error',
                        timer: 2000
                    });
                    this.var_res = e
                })
                return false;
            }else{
                this.axios.post(this.api_sendMessage, 
                    {
                        id_live: url_lk,
                        content: content,
                        sticker: dataimage
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#content_chat').val('');
                    $('.dataImg_chat').val('');
                    this.var_res = response
                    if(dataimage != ''){
                        $('#loading_show').css('display', 'none')
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    // this.var_res = e
                    if(e.response.data.type == 1){
                        this.showmodal_cashin = true
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            showConfirmButton: false,
                            title: e.response.data.message,
                            icon: 'error',
                            timer: 2000
                        });
                        $('.dataImg_chat').val('');
                    }
                })
                return false;
            }
        },
        changeTabSticker (tab) {
            this.tabSticker = tab
        },
        cashinFrm (e) {
            e.preventDefault()
            $('.loading_show').show().fadeIn(10)
            let file_img = document.getElementById('cash_picture').files[0]
            let point_money = $('#pointcash').val()
            if(file_img == undefined){
                $('#loading_show').css('display', 'none')
                this.$swal({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Vui lòng nhập hình ảnh',
                    showConfirmButton: false,
                    timer: 4000
                })
            }else{
                const form = new FormData();
                form.append('point', point_money);
                form.append('hinh_anh', file_img);
                // form.append('account_f8bet', this.accountf8);
                form.append('note', this.note);
                this.axios.post(this.api_cashin, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + window.localStorage.getItem('token')
                        }
                    }
                ).then(res => {
                    this.showmodal_cashin = false
                    $('#pointcash').val('')
                    this.accountf8 = this.note = this.point = ''
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.message,
                        showConfirmButton: false,
                        timer: 4000
                    })
                    window.location.href = '/thong-tin/' + this.numberus
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    if(e.response.status === 403){
                        this.$swal({
                            position: 'top-end',
                            icon: 'error',
                            title: e.response.data.message,
                            showConfirmButton: false,
                            timer: 3000
                        })
                    }
                })
            }
        },
        getRatiocash () {
            this.axios.get(this.api_ratiocash).then(res => {
                this.amount_change = res.data.data.amount_change
                this.point_change = res.data.data.point_change
                this.min_credit = res.data.data.min_credit
                this.max_credit = res.data.data.max_credit
                this.pointcode = this.min_credit * this.point_change
                this.xucode = this.min_credit * this.amount_change

                this.amount_change_debit = res.data.data.amount_change_debit
                this.min_debit = res.data.data.min_debit
                this.max_debit = res.data.data.max_debit

                this.valdebit = this.amount_change_debit * this.min_debit
            })
        },
        changenameFile () {
            let fileVideo = document.getElementById('cash_picture').files[0]
            $('label[for="cash_picture"] span').text(fileVideo.name);
        },
    }
}
</script>
<style>
</style>
