<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_info">
                <img src="../assets/images/bn.webp" alt="">
            </div>
            <div class="text_info">
                <div class="img_info">
                    <div class="mobile_change">
                        <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh !== null">
                        <img src="../assets/images/user.png" alt="" v-else>
                    </div>
                </div>
                <div class="info_user_right">
                    <p class="p-id">Live ID: {{ id }}</p>
                    <div class="p-name" v-if="type==1">
                        <p class="edit_name">
                            <span class="p_name_1">
                                {{ fullname }}
                            </span>
                        </p>
                        <span v-if="live > 0" class="p_name_2"><font-awesome-icon icon="fa-solid fa-headset" style="color: green;" /> Đang stream</span>
                        <span v-else class="p_name_2"><font-awesome-icon icon="fa-solid fa-power-off" /> offline</span>
                    </div>
                    <div class="follow_fan wd100" v-if="type==1">
                        <div class="follows">
                            <p class="p-tdf">Theo dõi</p>
                            <p class="p-sl">{{ follow }}</p>
                        </div>
                        <div class="fans">
                            <p class="p-tdf">Xu được tặng</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount_sticker) }}</p>
                        </div>
                        <div class="amount_info">
                            <p class="p-tdf">Xu của bạn</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_intro" style="margin-bottom: 30px;" v-if="typeus == 1">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <li :class="{active: tabSelected === 'payroll'}" v-on:click="changeTab('payroll')">Bảng lương</li>
                            <li :class="{active: tabSelected === 'timesheets'}" v-on:click="changeTab('timesheets')">Bảng chấm công</li>
                            <li :class="{active: tabSelected === 'penalties'}" v-on:click="changeTab('penalties')">Danh sách phạt</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div class="payroll_tb" v-if="tabSelected === 'payroll'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="30%">Lương</th>
                                        <th width="10%">Phạt</th>
                                        <th width="30%">Thực nhận</th>
                                        <th width="23%">Ngày tạo</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datas.length > 0">
                                    <tr v-for="(item, index) in datas" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.salary) }} vnđ
                                        </td>
                                        <td>
                                            {{ item.qc }}%
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.total_salary) }} vnđ
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5">Chưa có bảng lương</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                        <div class="payroll_tb" v-if="tabSelected === 'timesheets'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="15%">Thời gian live</th>
                                        <th width="28%">Lương</th>
                                        <th width="28%">Thực nhận</th>
                                        <th width="24%">Ngày làm việc</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datass.length > 0">
                                    <tr v-for="(item, index) in datass" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ item.time }} phút
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.salary) }} vnđ
                                        </td>
                                        <td>
                                            {{ new Intl.NumberFormat().format(item.total_salary) }} vnđ
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="5">Chưa có bảng chấm công</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallbacks" :totalCount="totalCounts" :limit="perPages" v-model="pages"></paginate>
                        </div>
                        <div class="payroll_tb" v-if="tabSelected === 'penalties'">
                            <table class="table_chanel">
                                <thead>
                                    <tr>
                                        <th width="7%">STT</th>
                                        <th width="40%">Nội dung</th>
                                        <th width="20%">Mức phạt</th>
                                        <th width="33%">Ngày phạt</th>
                                    </tr>
                                </thead>
                                <tbody v-if="datasss.length > 0">
                                    <tr v-for="(item, index) in datasss" :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>
                                            {{ item.info }}
                                        </td>
                                        <td>
                                            {{ item.value }} %
                                        </td>
                                        <td>{{ formatDate(item.ngay_tao) }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="4">Chưa có danh sách phạt</td>
                                    </tr>
                                </tbody>
                            </table>
                            <paginate @update:modelValue="clickCallbackss" :totalCount="totalCountss" :limit="perPagess" v-model="pagess"></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'Payroll_nm',
    data () {
        return {
            tabSelected: 'payroll',
            page: 1,
            perPage: 15,
            totalCount: 0,
            datas: [],
            token: window.localStorage.getItem('token'),
            typeus: window.localStorage.getItem('typeus'),
            listPayroll: [],
            listTimesheets: [],
            pages: 1,
            perPages: 15,
            totalCounts: 0,
            datass: [],
            listPenalties: [],
            pagess: 1,
            perPagess: 15,
            totalCountss: 0,
            datasss: [],
            hinh_anh: '',
            id: '',
            type: '',
            amount: '',
            amount_sticker: '',
            logoWb: ''
        }
    },
    components: {
    },
    created () {
        this.GetPayroll()
        this.GetTimesheets()
        this.GetPenalties()
        this.getListInfo()
        this.getInfoLink()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.id = response.data.data.user.id
                this.fullname = response.data.data.user.fullname
                this.live = response.data.data.user.live
                this.hinh_anh = response.data.data.user.hinh_anh
                this.type = response.data.data.user.type
                this.follow = response.data.data.user.follow_total
                this.amount = response.data.data.user.amount
                this.amount_sticker = response.data.data.user.amount_sticker
            }).catch(e => {
                if (e.response.status === 401) {
                    setTimeout(function() {
                        this.axios.post(this.api_logout, {
                        }).then(res => {
                            if (res.data.success === true) {
                                localStorage.clear();
                            }
                        })
                    }.bind(this), 5000);
                    setTimeout(function() {
                        window.location.href = '/'
                    }, 13000);
                }
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            // var gpg = splitdate[1].split(':');
            // gpg[0] + ':' + gpg[1] + ' ' + 
            return year + '-' + month + '-' + day;
        },
        GetPayroll () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let yearMonth = yearP + '-' + monthP
            this.axios.get(this.api_payroll + '?date=' + yearMonth,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listPayroll = res.data.data
                this.totalCount = this.listPayroll.length
                this.paginatedData()
            })
        },
        paginatedData () {
            this.datas = this.listPayroll.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        GetTimesheets () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            let dateP = today.getDate()
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let time_start = yearP + '-' + monthP + '-' + '01'
            let time_end = yearP + '-' + monthP + '-' + dateP
            this.axios.get(this.api_timesheets + '?time_start=' + time_start + '&time_end=' + time_end,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listTimesheets = res.data.data
                this.totalCounts = this.listTimesheets.length
                this.paginatedDatas()
            })
        },
        paginatedDatas () {
            this.datass = this.listTimesheets.slice((this.pages - 1) * this.perPages, this.pages * this.perPages)
        },
        clickCallbacks (pageNum) {
            this.pages = pageNum;
            this.paginatedDatas();
        },
        GetPenalties () {
            let today = new Date();
            let yearP = today.getFullYear()
            let monthP = ''
            let dateP = today.getDate()
            if((today.getMonth()+1) < 10){
                monthP = '0' + (today.getMonth()+1)
            }else{
                monthP = (today.getMonth()+1)
            }
            let time_start = yearP + '-' + monthP + '-' + '01'
            let time_end = yearP + '-' + monthP + '-' + dateP
            this.axios.get(this.api_penalties + '?time_start=' + time_start + '&time_end=' + time_end,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                this.listPenalties = res.data.data
                this.totalCounts = this.listPenalties.length
                this.paginatedDatass()
            })
        },
        paginatedDatass () {
            this.datasss = this.listPenalties.slice((this.pagess - 1) * this.perPagess, this.pagess * this.perPagess)
        },
        clickCallbackss (pageNum) {
            this.pagess = pageNum;
            this.paginatedDatass();
        },
    }
}
</script>
<style>
</style>
