<template>
    <div class="wrapper_home mg_top">
        <div class="bgr_live_home">
            <div class="container ner_responesize">
                <div class="root_liveRoom">
                    <div :class="{active_margin: listLiveHome.length > 0}">
                        <div class="row">
                            <div class="wrapper_liveroom">
                                <div class="video_live_room" v-for="(item, index) in listLiveHome.slice(0, 5)" v-bind:key="index">
                                    <div v-if="tabSelected === 'tab' + index">
                                        <div class="video_f8live_home">
                                            <div class="mobile_stream">
                                                <vue3-video-player
                                                    autoplay
                                                    :core="HLSCore"
                                                    :src="item.detail.hls_src"
                                                    title=""
                                                    resolution="408p"
                                                    :view-core="viewCore.bind(null, `videolivecams${index}`)"
                                                    :id="`videolivecams${index}`"
                                                />
                                            </div>
                                        </div>
                                        <router-link class="room_live_a" v-bind:to="{ name: 'DetailLiveBG_nm', params: { id: item.bigo_id } }">Vào phòng live</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="tab_itemlive">
                                <div v-bind:class="'tabhome tab' + index" v-on:click="changeTab('tab' + index)" v-for="(item, index) in listLiveHome.slice(0, 5)" :key="index">
                                    <p :class="{active: tabSelected == 'tab' + index }">
                                        <img :src="item.cover_l" alt="">
                                    </p>
                                    <div class="in_img_idollive"><img src="../assets/images/pl.png" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="root_item_live_home">
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveGame.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Live game</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveGame.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.id_live } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="link_web + item.background" alt="" v-if="item.background != null">
                                        <img src="../assets/images/lv.png" alt="" v-else>
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.hinh_anh" alt="" v-if="item.hinh_anh != null">
                                            <img src="../assets/images/gx.png" alt="" v-else>
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.fullname }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.total_view }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <!-- <a @click="showModal = true" v-if="token==null">Xem thêm</a> -->
                    <router-link to="/live">Xem thêm</router-link>
                </p>
            </div>
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveHome.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Idol</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveHome.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailLiveBG_nm', params: { id: item.bigo_id } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item.cover_l" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.data1" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.nick_name.substring(0, 8) }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.user_count }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <!-- <a @click="showModal = true" v-if="token==null">Xem thêm</a> -->
                    <router-link to="/idol-live">Xem thêm</router-link>
                </p>
            </div>
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listLiveHome.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Live game</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLivegameBg.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailGame_nm', params: { id: item.bigo_id } }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item.cover_l" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.data1" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.nick_name.substring(0, 8) }}</h3>
                                            <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.user_count }}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <!-- <a @click="showModal = true" v-if="token==null">Xem thêm</a> -->
                    <router-link to="/live-game">Xem thêm</router-link>
                </p>
            </div>
            <div class="wrapper_idol_live_home" v-if="listLiveidol.length > 0">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Live show 18+</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listLiveidol.slice(0,10)" :key="index">
                            <div class="item_live">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item[0], free: 0} }" @click="getLocalstoragePage">
                                    <div class="img_live">
                                        <img :src="item[1]" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item[1]" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item[2].substring(0, 10) }}</h3>
                                            <!-- <div class="type_live">
                                                <p>
                                                    <img src="../assets/images/eye.svg" alt="">
                                                    <span class="sp-02">{{ item.viewersCount }}</span>
                                                </p>
                                            </div> -->
                                        </div>
                                        <img :src="item[3]" alt="" class="img_live_green">
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <!-- <a @click="showModal = true" v-if="token==null">Xem thêm</a> -->
                    <router-link to="/video-propose">Xem thêm</router-link>
                </p>
            </div>
            <div class="wrapper_idol_live_home wrapper_idol_live_home2" v-if="listFollowidol.length > 0 && token != null">
                <div class="title_item_idol">
                    <h3>
                        <img src="../assets/images/ddx.png" alt="">
                        <span>Idol follow</span>
                    </h3>
                </div>
                <div class="list_idol_mobile_live_xnxx">
                    <div class="row">
                        <div class="col-md-2 col-sm-2 col-xs-3 wp-item-live" v-for="(item, index) in listFollowidol.slice(0,10)" :key="index">
                            <!-- type_idol=1 && type=1 idol live web -->
                            <!-- type_idol=0 && type=1 live 18+ -->
                            <!-- type_idol=0 && type=3 bigo idol -->
                            <!-- type_idol=0 && type=4 bigo game -->

                            <div class="item_live" v-if="item.type_idol==1 && item.type==1">
                                <router-link v-bind:to="{ name: 'LiveDetail_nm', params: { id: item.id, idlive: item.live_id} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="link_web + item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="link_web + item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="link_web + item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="unfollowIdol($event)" :data_name="item.id">
                                </div>
                            </div>
                            <div class="item_live" v-else-if="item.type_idol==0 && item.type==1">
                                <router-link v-bind:to="{ name: 'DetailVideoPropose_nm', params: { id: item.live_id, free: 0} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="cancelFolow($event)" :data_name="item.username">
                                </div>
                            </div>
                            <div class="item_live" v-else-if="item.type_idol==0 && item.type==3">
                                <router-link v-bind:to="{ name: 'DetailLiveBG_nm', params: { id: item.live_id} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="cancelFolow($event)" :data_name="item.username">
                                </div>
                            </div>
                            <div class="item_live" v-else>
                                <router-link v-bind:to="{ name: 'DetailGame_nm', params: { id: item.live_id} }" @click="getLocalstoragePage" v-if="item.is_live==1">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </router-link>
                                <a @click="offlineIdol" v-else style="cursor: pointer;">
                                    <div class="img_live">
                                        <img :src="item.avatar" alt="">
                                        <div class="hover_icon">
                                            <font-awesome-icon icon="fa-solid fa-caret-right" />
                                        </div>
                                    </div>
                                    <div class="text_item_live">
                                        <div class="img_live_small">
                                            <img :src="item.avatar" alt="">
                                        </div>
                                        <div class="text_live_small">
                                            <h3>{{ item.username.substring(0, 10) }}</h3>
                                        </div>
                                    </div>
                                </a>
                                <div class="unfollow">
                                    <img src="../assets/images/unfl.png" alt="" @click="cancelFolow($event)" :data_name="item.username">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="detail_mobile_live">
                    <router-link to="/list-idol-follow">Xem thêm</router-link>
                </p>
            </div>
        </div>
        <!-- modal login -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal" @click="showModal = false"></div>
            <div class="modal" v-if="showModal">
                <div class="wp-modal-login">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <Login_f8 @showMd="showModalrg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal login -->
        <!-- modal register -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showModal_dk" @click="showModal_dk = false"></div>
            <div class="modal" v-if="showModal_dk">
                <div class="wp-modal-register">
                    <div class="header-modal">
                        <button class="close-modal_login" @click="showModal_dk = false"><img src="../assets/images/close_md.png" alt=""></button>
                    </div>
                    <div class="content-modal_tab">
                        <div>
                            <div class="logo_modal_login">
                                <div class="logo_mdal">
                                    <div class="img_md">
                                        <img :src="link_web + logoWb" alt="">
                                    </div>
                                </div>
                            </div>
                            <div class="form_dangky">
                                <Register_f8 @showMd="showModallg"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end modal register -->
        <!-- modal thông báo -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showmodal_noti" @click="showmodal_noti = false"></div>
            <div class="modal modal_info" v-if="showmodal_noti">
                <div class="wp-modal-info">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/gifttb.gif" alt="">
                    </a>
                    <button class="close-modal_login close_gift" @click="hidden_pp"><img src="../assets/images/closegf.png" alt=""></button>
                </div>
            </div>
        </div>
        <!-- end modal thông báo -->
        <!-- modal popup -->
        <div id="modal-example" class="modal-login">
            <div class="overlay" v-if="showmodal_popup" @click="showmodal_popup = false"></div>
            <div class="modal modal_info" v-if="showmodal_popup">
                <div class="wp-modal-info">
                    <a :href="linkf8bet" target="_blank">
                        <img src="../assets/images/gifttb.gif" alt="">
                    </a>
                    <button class="close-modal_login close_gift" @click="showmodal_popup = false"><img src="../assets/images/closegf.png" alt=""></button>
                </div>
            </div>
        </div>
        <!-- end modal popup -->

        <!-- modal event -->
            <div id="modal-example" class="modal-event">
                <div class="overlay" v-if="showmodal_event" @click="showmodal_event = false"></div>
                <div class="modal" v-if="showmodal_event">
                    <div class="wp-modal-event">
                        <div class="header-modal">
                            <ul class="list_menu_modal">
                                <li class="active">Sự kiện</li>
                            </ul>
                            <button class="close-modal_login" @click="showmodal_event = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab" v-for="(item, index) in listEvent" :key="index">
                            <h2 class="tittle_event">{{ item.title }}</h2>
                            <p class="date_event">Thời gian: {{ item.tu }} - {{ item.den }}</p>
                            <p class="img_event"><img :src="link_web + item.hinh_anh" alt=""></p>
                            <div class="wp_content_ev">
                                <!-- <p>Nội dung:</p>  -->
                                <div class="content_event" v-html="item.content"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!-- end modal event -->
    </div>
</template>

<script>
import HLSCore from '@cloudgeek/playcore-hls'
// import Banner_adv from '@/components/Banner'
// import { Carousel, Slide, Pagination } from 'vue3-carousel'
import $ from 'jquery'
// import SocketIO from 'socket.io-client'

import Login_f8 from '@/components/Login'
import Register_f8 from '@/components/Register'
export default {
    name: 'Home_live',
    setup() {
		return {
			breakpoints: {
                0: {
					itemsToShow: 1.5,
					snapAlign: "start"
				},
				431: {
					itemsToShow: 2,
					snapAlign: "start"
				},
				768: {
					itemsToShow: 3,
					snapAlign: "start"
				},
				1024: {
					itemsToShow: 3,
					snapAlign: "start"
				},
                1280: {
					itemsToShow: 4,
					snapAlign: "start"
				},
			}
		};
	},
    data () {
        return {
            showmodal_noti: false,
            showModal: false,
            showModal_dk: false,
            tabSelected: 'tab0',
            liveRoom: [],
            players: {},
            volume: 90,
            HLSCore,
            check: 0,
            listLive: [],
            listLiveHome: [],
            token: window.localStorage.getItem('token'),
            rank: window.localStorage.getItem('rank'),
            checkes: '',
            listBxh: [],
            listBxhs: [],
            listIdolAll: [],
            listIdol18All: [],
            hot_idol: [],
            typeus: window.localStorage.getItem('typeus'),
            numberus: parseInt(window.localStorage.getItem('numberus')),
            type_live: parseInt(window.localStorage.getItem('type_live')),
            other: '',
            length_hot: 6,
            lenghVideo: 12,
            listGift: [],
            listIdolAll1: [],
            listIdol18All1: [],
            hot_idol1: [],
            country: 'Vietnam',
            linkf8bet: '',
            check_deveci: 1,
            linkgame: '',
            listLiveidol: [],
            codeCapchar: Math.floor(1000 + Math.random() * 9000),
            listVideoIdol: [],
            liveMatch: [],
            listItemdate1: [],
            tabSelectedpl: '',
            tabs: '',
            checkMobile: 0,
            showmodal_popup: false,
            listFollowidol: [],
            listLiveAsian: [],
            listLiveGame: [],
            listLivegameBg: '',
            logoWb: '',
            listEvent: [],
            showmodal_event: false
        }
    },
    components: {
        // Carousel,
        // Slide,
        // Pagination,
        Login_f8,
        Register_f8,
    },
    mounted () {
        this.$nextTick(() => {
        })
        let isMobile = /Mobi/i.test(navigator.userAgent)
        if(isMobile == true){
            this.checkMobile = 1
        }
        let ss = sessionStorage.getItem("session");
        if(this.token == null && ss == null){
            var title_ss = 'user0';
            var num_ran = Math.floor(Math.random() * 1000000000);
            var session = title_ss + num_ran
            sessionStorage.setItem("session", session);
        }
    },
    created () {
        this.getDataRoomLive()
        this.getListBxh()
        this.getListBxhs()
        this.getDataListGift()
        this.getlocation()
        this.getInfoLink()
        this.getDataliveidolApi()
        this.getListVideoIdol()
        if(this.token!=null){
            sessionStorage.clear();
            this.getListFollow()
        }
        this.getDataliveAsian()
        this.getDataLiveGame()
        this.getDataliveBg()
        this.getDataEvent()
    },
    computed: {
        
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink).then((response) => {
                this.linkf8bet = response.data.data.list_data.banner
                this.linkgame = response.data.data.list_data.link_game;
                this.logoWb = response.data.data.list_data.logo;
            })
        },
        showlogin () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Vui lòng đăng nhập',
                showConfirmButton: false,
                timer: 3000
            })
        },
        getDataRoomLive () {
            this.axios.get(this.api_listBG).then((response) => {
                this.listLiveHome = this.shuffle(response.data.data)
            })
        },
        searchIdolHot (e) {
            e.preventDefault()
            let keyname = $('.key_searchIdol').val().toLowerCase()
            let dataArray = []
            $.each(this.hot_idol1, function() {
                if ( this.fullname.toLowerCase().indexOf(keyname) != -1) {
                    return dataArray.push(this);
                }
            });
            this.hot_idol = dataArray
        },
        getDataliveidolApi () {
            this.axios.get(this.api_live18_dexuat).then((response) => {
                let isMobile = /Mobi/i.test(navigator.userAgent)
                if(isMobile == true){
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[4] == 200 && d[6] == 1))
                }else{
                    this.listLiveidol = this.shuffle(response.data.data.filter(d => d[6] == 1))
                }
            })
        },
        viewCore(id, player) {
            this.players[id] = player;
            this.play(id)
        },
        viewCores(id, player) {
            this.players[id] = player;
        },
        play(id) {
            this.players && this.players[id] && this.players[id].play();
        },
        pip(id) {
            this.players &&
            this.players[id] &&
            this.players[id].requestPictureInPicture();
        },
        retrieveAllCustomer (url) {
            this.axios.get(url).then(res => {
                if(res.status===200){
                    this.check = 1
                }
            }).catch(e => {
                if (e.response.status === 403) {
                    this.check = 0
                }else{
                    this.check = 1
                }
            })
            return this.check;
        },
        getListBxh () {
            this.axios.get(this.api_listBxh).then((response) => {
                this.listBxh = response.data.data.list_user.slice(0, 9)
            })
        },
        getListBxhs () {
            this.axios.get(this.api_rankfollow).then((response) => {
                this.listBxhs = response.data.data.list_user.slice(0, 9)
            })
        },
        seandMbut (id) {
            if(id != null){
                this.axios.post(this.api_offstream, {
                    id: id,
                }).then(res => {
                    this.other = res
                }).catch(e => {
                    this.other = e
                })
            }
        },
        hidden_pp () {
            this.showmodal_noti = false
            this.$cookies.set(100, 'close', "600s");
        },
        getDataListGift () {
            this.axios.get(this.api_listGift).then((response) => {
                this.listGift = response.data.data.list_data
                if(this.listGift.length > 0 && this.$cookies.get(100) !== 'close'){
                    setTimeout(() => {
                        this.showmodal_noti = true
                    }, 3000);
                }
            })
        },
        getlocation () {
            this.axios.get('https://geolocation-db.com/json/').then((response) => {
                this.country = response.data.country_name
            })
        },
        viewmodal_rg () {
            this.showModal_dk = true
            this.showModal = false
        },
        viewmodal_lg () {
            this.showModal = true
            this.showModal_dk = false
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        getListVideoIdol () {
            this.axios.get(this.api_listvideoidol).then((response) => {
                this.listVideoIdol = response.data.items
            })
        },
        changeTabpl (tab) {
            this.tabSelectedpl = tab
            this.tabs = tab
        },
        shuffle(arra1) {
            var ctr = arra1.length, temp, index;
            while (ctr > 0) {
                index = Math.floor(Math.random() * ctr);
                ctr--;
                temp = arra1[ctr];
                arra1[ctr] = arra1[index];
                arra1[index] = temp;
            }
            return arra1;
        },
        getLocalstoragePage () {
            window.localStorage.setItem('hiddenhd', 1)
            $('#app').addClass('hideheader')
        },
        clickPlaypp () {
            this.showModal_dk = true
            this.showmodal_popup = false
        },
        getListFollow () {
            this.axios.get(this.api_listfollow,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                this.listFollowidol = response.data.data
            })
        },
        offlineIdol () {
            this.$swal({
                position: 'top-end',
                icon: 'error',
                title: 'Idol hiện tại đã kết thúc phiên live',
                showConfirmButton: false,
                timer: 3000
            })
        },
        cancelFolow (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var nameidol = event.target.getAttribute('data_name')
            this.axios.post(this.api_followidol, {
                    name_idol: nameidol,
                    type: 0
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.getListFollow()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 403){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getDataliveAsian () {
            this.axios.get(this.api_listAsian).then((response) => {
                this.listLiveAsian = this.shuffle(response.data.data.filter(d => d[4] == 200 && d[6] == 1));
            })
        },
        showModalrg () {
            this.showModal = false
            this.showModal_dk = true
        },
        showModallg () {
            this.showModal = true
            this.showModal_dk = false
        },
        getDataLiveGame () {
            this.axios.get(this.api_listlive).then((response) => {
                this.listLiveGame = response.data.data.list_user
            })
        },
        unfollowIdol (event) {
            event.preventDefault()
            $('.loading_show').show().fadeIn(10)
            var ididol = event.target.getAttribute('data_name')
            this.axios.post(this.api_unfollow, 
                {
                    id: ididol
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }
            ).then(res => {
                $('#loading_show').css('display', 'none')
                this.getListFollow()
                this.$swal({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.message,
                    showConfirmButton: false,
                    timer: 4000
                })
            }).catch(e => {
                $('#loading_show').css('display', 'none')
                if(e.response.status === 403){
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                }
            })
        },
        getDataliveBg () {
            // this.axios.get(this.api_GameBG).then((response) => {
            //     this.listLivegameBg = this.shuffle(response.data.data)
            // })
            this.axios.get(this.api_ListGameBG).then((response) => {
                this.listLivegameBg = this.shuffle(response.data.data.data)
            })
        },
        getDataEvent () {
            this.axios.get(this.api_dataEvent).then(res => {
                this.listEvent = res.data.data.list_data.slice(0,1)
                if(this.listEvent.length > 0){
                    this.showmodal_event = true
                }
            })
        },
    }
}
</script>