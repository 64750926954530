<template>
    <div class="root_info_user">
        <div class="info_wrapper">
            <div class="wrapper_info">
                <img src="../assets/images/bn.webp" alt="">
            </div>
            <div class="text_info">
                <div class="img_info">
                    <div class="mobile_change">
                        <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh !== null">
                        <img src="../assets/images/user.png" alt="" v-else>
                    </div>
                </div>
                <div class="info_user_right" :class="{ cls_vip5: level == 'Vip 5' }">
                    <div class="level_infous">
                        <p class="p-id">Level: <img :src="link_web + level_icon" alt="" class="imglevel"> {{ level }}</p>
                        <p class="level_next" v-if="level != 'Vip 5'">Bạn cần thêm {{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(level_exp_next) }} xu để tăng cấp lên <b>{{ level_name_next }}</b></p>
                    </div>
                    <div class="p-name" v-if="type==1">
                        <p class="edit_name">
                            <span class="p_name_1">
                                {{ fullname }}
                            </span>
                        </p>
                        <span v-if="live > 0" class="p_name_2"><font-awesome-icon icon="fa-solid fa-headset" style="color: green;" /> Đang stream</span>
                        <span v-else class="p_name_2"><font-awesome-icon icon="fa-solid fa-power-off" /> offline</span>
                    </div>
                    <p class="p-name pname_edit" v-else>
                        <span class="p_name_1">{{ fullname }}</span>
                    </p>
                    <div class="follow_fan wd100" v-if="type==1">
                        <div class="follows">
                            <p class="p-tdf">Theo dõi</p>
                            <p class="p-sl">{{ follow }}</p>
                        </div>
                        <div class="fans">
                            <p class="p-tdf">Xu được tặng</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount_sticker) }}</p>
                        </div>
                        <div class="amount_info">
                            <p class="p-tdf">Xu của bạn</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }}</p>
                        </div>
                    </div>
                    <div class="follow_fan follow_fanAmount" v-else>
                        <div class="amount_info">
                            <p class="p-tdf">Ví idol</p>
                            <p class="p-sl">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(amount) }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper_intro" style="margin-bottom: 30px;">
                <div class="previewidol_live">
                    <div class="cat_chanel">
                        <ul>
                            <li><img :src="link_web + logoWb" alt=""></li>
                            <li :class="{active: tabSelected === 'notification'}" v-on:click="changeTab('notification')">Thông báo</li>
                        </ul>
                    </div>
                    <div class="content_tab_chanel">
                        <div v-if="tabSelected === 'notification'">
                            <div class="scrollNotiPage">
                                <ul>
                                    <li :class="{notview: item.view_web==1}" v-for="(item, index) in datas" :key="index">
                                        <p v-if="item['arrinfo'][0].action == 'register_approve'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được duyệt làm idol. Tên đăng nhập <b :data_id="item.id">{{ item['arrinfo'][0].username }}</b>, mật khẩu <b :data_id="item.id">{{ item['arrinfo'][0].password }}</b> <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'register_cancel'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> không được duyệt làm idol. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'qc_create'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã bị phạt lần thứ <b :data_id="item.id">{{ item['arrinfo'][0].level }}</b> mức phạt <b :data_id="item.id">{{ item['arrinfo'][0].value }}%</b> <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'qc_delete'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được xóa phạt. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'update_streamkey'" :data_id="item.id">Tài khoản <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã được cấp phát luồng livestream. <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'send_sticker_gift'" :data_id="item.id"> <b :data_id="item.id">{{ item['arrinfo'][0].fullname }}</b> đã tặng bạn <b :data_id="item.id">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item['arrinfo'][0].amount) }} vnđ</b> <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'cash_credit_cancel'" :data_id="item.id">Thao tác nạp <b :data_id="item.id">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item['arrinfo'][0].amount) }} vnđ</b> đã bị hủy <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p class="accept_green" v-else-if="item['arrinfo'][0].action == 'cash_credit_accept'" :data_id="item.id"> Nạp <b :data_id="item.id">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item['arrinfo'][0].amount) }} vnđ</b> đã được duyệt <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p v-else-if="item['arrinfo'][0].action == 'cash_debit_cancel'" :data_id="item.id">Thao tác rút <b :data_id="item.id">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item['arrinfo'][0].amount) }} vnđ</b> đã bị hủy <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                        <p class="accept_green" v-else-if="item['arrinfo'][0].action == 'cash_debit_accept'" :data_id="item.id">Rút <b :data_id="item.id">{{ new Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(item['arrinfo'][0].amount) }} vnđ</b> đã được duyệt <span :data_id="item.id">{{ formatDate(item.created_date) }}</span></p>
                                    </li>
                                </ul>
                            </div>
                            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import $ from 'jquery'
export default {
    name: 'Notification_nm',
    data () {
        return {
            tabSelected: 'notification',
            page: 1,
            perPage: 20,
            totalCount: 0,
            datas: [],
            token: window.localStorage.getItem('token'),
            listNotification: [],
            logoWb: '',

            fullname: '',
            hinh_anh: '',
            level: '',
            level_exp_next: '',
            level_name_next: '',
            live: '',
            amount_sticker: '',
            amount: '',
            type: '',
        }
    },
    components: {
    },
    created () {
        this.GetNoti()
        this.getInfoLink()
        this.getListInfo()
    },
    methods: {
        getInfoLink () {
            this.axios.get(this.api_previewLink).then((response) => {
                this.logoWb = response.data.data.list_data.logo
            })
        },
        changeTab (tab) {
            this.tabSelected = tab
        },
        formatDate (date) {
            var splitdate = date.split(' ')
            var datePart = splitdate[0].match(/\d+/g),
            year = datePart[2].substring(0),
            month = datePart[1], 
            day = datePart[0];
            // var gpg = splitdate[1].split(':');
            // gpg[0] + ':' + gpg[1] + ' ' + 
            return year + '-' + month + '-' + day;
        },
        GetNoti () {
            this.axios.get(this.api_listNoti,
                {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }
            ).then((response) => {
                let listNoti = response.data.data.notifications
                this.numberNoti = response.data.data.num_notifi
                const arr = [];
                listNoti.forEach(item => {
                    let arrinfo = JSON.parse(item.content)
                    arr.push({...item, arrinfo});
                });
                setTimeout(() => {
                    this.listNotification = arr;
                    this.totalCount = this.listNotification.length
                    this.paginatedData()
                }, 800);
            })
        },
        paginatedData () {
            this.datas = this.listNotification.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getListInfo () {
            this.axios.get(this.api_infouser, {
                headers: {
                    Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            }).then((response) => {
                this.fullname = response.data.data.user.fullname
                this.hinh_anh = response.data.data.user.hinh_anh
                this.level = response.data.data.user.level
                this.level_exp_next = response.data.data.user.level_exp_next
                this.level_name_next = response.data.data.user.level_name_next
                this.live = response.data.data.user.live
                this.follow = response.data.data.user.follow_total
                this.amount_sticker = response.data.data.user.amount_sticker
                this.amount = response.data.data.user.amount
                this.type = response.data.data.user.type
            })
        },
    }
}
</script>
<style>
</style>
