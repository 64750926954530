<template>
    <div class="root_info_user">
        <div class="container ner_support">
            <div class="list_chat_support list_chat_support_faq">
                <router-link to="/faq" class="comeback_support"><img src="../assets/images/cb.png" alt=""></router-link>
                <p class="btn_addnew_sp"><span @click="showModalsp = true">+ Thêm mới</span></p>
                <div class="item_support" v-for="(item, index) in datas" :key="index" :data_id="item.id">
                    <div class="img_support" :data_id="item.id" :data_title="item.title" @click="getListspdt($event)">
                        <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null" :data_id="item.id" :data_title="item.title">
                        <img src="../assets/images/user.png" alt="" :data_id="item.id" :data_title="item.title" v-else>
                    </div>
                    <div class="name_content_support" :class="{opacity: item.status == 2}" :data_id="item.id" :data_title="item.title" @click="getListspdt($event)">
                        <p class="font1" :data_id="item.id" :data_title="item.title">{{ fullname }} <img src="../assets/images/view.png" alt="" v-if="item.status == 1" :data_id="item.id" :data_title="item.title" class="newgif"> <span :data_id="item.id" :data_title="item.title">{{ item.ngay_tao }}</span></p>
                        <div class="content_sp" :data_id="item.id" :data_title="item.title">
                            {{ item.title }}
                        </div>
                    </div>
                    <div class="delete_support" :data_id="item.id">
                        <img src="../assets/images/xoa.png" alt="" @click="deleteSupport($event)" :data_id="item.id">
                    </div>
                </div>
            </div>
            <paginate @update:modelValue="clickCallback" :totalCount="totalCount" :limit="perPage" v-model="page"></paginate>
            <!-- modal chat -->
            <div id="modal-example" class="modal-login">
                <div class="overlay" v-if="showModalsp" @click="showModalsp = false"></div>
                <div class="modal" v-if="showModalsp">
                    <div class="wp-modal-login wp-modal-support">
                        <div class="header-modal">
                            <ul class="list_menu_modal">
                                <!-- <li class="img_modal"><img src="../assets/images/logo.png"></li> -->
                                <li class="active">Trợ giúp</li>
                            </ul>
                            <button class="close-modal_login" @click="showModalsp = false"><img src="../assets/images/close_md.png" alt=""></button>
                        </div>
                        <div class="content-modal_tab">
                            <div>
                                <form action="" method="post" id="frm_chat" @submit="sendSupports">
                                    <div>
                                        <Select2 v-model="topic" :options="listtopic" placeholder="Chọn tiêu đề">
                                        </Select2>
                                    </div>
                                    <!-- <select v-model="topic">
                                        <option value="" selected>Chọn tiêu đề</option>
                                        <option :value="item.id" v-for="(item, index) in listtopic" :key="index">{{ item.title }}</option>
                                    </select> -->
                                    <!-- <input type="text" v-model="titlesp" placeholder="Tiêu đề"> -->
                                    <input type="text" v-model="content" placeholder="Nội dung">
                                    <button type="submit">Gửi</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end modal chat -->
            <!-- support detail -->
            <div class="root_sp_dt">
                <div class="wrapper_sp_dt">
                    <div class="title_sp_dt">
                        <div>
                            <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null">
                            <img src="../assets/images/user.png" alt="" v-else>
                            <span>{{ name_sp }}</span>
                        </div>
                        <p><img src="../assets/images/close.png" alt="" @click="closeSupport"></p>
                    </div>
                    <div class="wrapper_detail_list">
                        <div class="scroll_div_support">


                            <div class="item_chat_detail" v-for="(item, index) in listspdt" :key="index">
                                <!-- user send admin -->
                                <div :class="'wp_item_chatdt_right message_' + item.id" v-if="item.type == 1">
                                    <div class="content_chatdt">
                                        <p class="ngaytao_chat ngaytao_chat1" v-if="index == 0 || listspdt[index -1].type !== item.type"><span>{{ fullname }}</span></p>
                                        <div class="content_child">
                                            <div class="left_content_mes">
                                                <div v-if="item.type_mes == 1">{{ item.content }}</div>
                                                <div v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></div>
                                                <div v-else><a :href="item.content" target="_blank">{{ item.content }}</a></div>
                                            </div>
                                        </div>
                                        <p class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listspdt[index -1])">{{ item.ngay_tao }}</p>
                                    </div>
                                    
                                    <div class="img_chat_detail" v-if="index == 0 || listspdt[index -1] && listspdt[index -1].type !== item.type">
                                        <img :src="link_web + hinh_anh" alt="" v-if="hinh_anh != null">
                                        <img src="../assets/images/user.png" alt="" v-else>
                                    </div>
                                    <div class="img_chat_detail" v-else>
                                    </div>
                                </div>
                                <!-- admin send user -->
                                <div :class="'wp_item_chatdt_left message_' + item.id" v-else>
                                    <div class="img_chat_detail" v-if="index == 0 || listspdt[index -1] && listspdt[index -1].type !== item.type">
                                        <img src="../assets/images/admin.png" alt="">
                                    </div>
                                    <div class="img_chat_detail" v-else>
                                    </div>

                                    <div class="content_chatdt" :class="{active: last_id == item.id}">
                                        <p class="ngaytao_chat ngaytao_chat1" v-if="index == 0 || listspdt[index -1].type !== item.type"><span>Hải Yến</span></p>
                                        <div class="content_child">
                                            <div class="left_content_mes">
                                                <div v-if="item.type_mes == 1" v-html="item.content"></div>
                                                <div v-else-if="item.type_mes == 2"><a :href="link_web + item.url_image" target="_blank"><img :src="link_web + item.url_image" alt=""></a></div>
                                                <div v-else><a :href="item.content" target="_blank">{{ item.content }}</a></div>
                                            </div>
                                        </div>
                                        <p class="ngaytao_chat date_ngaytao" v-if="isSameUser(item, listspdt[index -1])">{{ item.ngay_tao }}</p>
                                        <ul class="listtp_other" v-if="last_id == item.id">
                                            <li @click="clickLoadSupport($event)" v-for="(item, index) in listtp" :data_id="item.id" :key="index" :data_title="item.title" :data_content="item.content">{{ item.title }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div class="form_chat_support">
                            <form action="" method="post" id="frm_chatsupport" @submit="sendSupport">
                                <input type="text" class="content_rep" v-model="content" placeholder="Nội dung">
                                <div class="input_file_img">
                                    <input type="file" name="file_img_sp" id="file_img_sp" @change="submitImg($event)"/>
                                    <label class="file_img_sp" for="file_img_sp">
                                        <font-awesome-icon icon="fa-solid fa-image" />
                                    </label>
                                </div>
                                <button type="submit">Gửi</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end support detail -->
        </div>
        <input type="hidden" class="id_support" value="">
        <input type="hidden" class="title_support" value="">
    </div>
</template>

<script>
import $ from 'jquery'
import SocketIO from 'socket.io-client'
import Select2 from 'vue3-select2-component';
export default {
    name: 'Support_nm',
    data () {
        return {
            listsp: [],
            fullname: '',
            hinh_anh: '',
            page: 1,
            perPage: 20,
            totalCount: 0,
            datas: [],
            showModalsp: false,
            listtopic: [],
            topic: '',
            titlesp: '',
            content: '',
            token: window.localStorage.getItem('token'),

            id: '',
            listspdt: [],
            other: '',
            listtp: [],
            last_id: '',
            checkSend: 0,
            name_sp: ''
        }
    },
    components: {
        Select2
    },
    created () {
        this.getListsp()
        setInterval(this.getListsp, 30000);
        this.getListInfo()
        this.getListtopic()
        this.connectSupport()
    },
    methods: {
        getListInfo () {
            if(this.token != null){
                this.axios.get(this.api_infouser, {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }).then((response) => {
                    this.fullname = response.data.data.user.fullname
                    this.hinh_anh = response.data.data.user.hinh_anh
                }).catch(e => {
                    if (e.response.status === 401) {
                        setTimeout(function() {
                            this.axios.post(this.api_logout, {
                            }).then(res => {
                                if (res.data.success === true) {
                                    localStorage.clear();
                                }
                            })
                        }.bind(this), 5000);
                        setTimeout(function() {
                            window.location.href = '/'
                        }, 13000);
                    }
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                this.fullname = 'Khách' + secsion.substring(10, 14)
                this.hinh_anh = 'upload/avatar/1053254613.png'
            }
        },
        getListsp () {
            if(this.token != null){
                this.axios.get(this.api_listchatsp, {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem('token')
                    }
                }).then((response) => {
                    this.listsp = response.data.data
                    this.totalCount = this.listsp.length
                    this.paginatedData()
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                this.axios.get(this.api_listchatspNologin + '?session_id=' + secsion).then((response) => {
                    this.listsp = response.data.data
                    this.totalCount = this.listsp.length
                    this.paginatedData()
                })
            }
        },
        paginatedData () {
            this.datas = this.listsp.slice((this.page - 1) * this.perPage, this.page * this.perPage)
        },
        clickCallback (pageNum) {
            this.page = pageNum;
            this.paginatedData();
        },
        getListtopic () {
            this.axios.get(this.api_listtopic).then((response) => {
                let listtopic = response.data.data
                let datatp = []
                listtopic.forEach(item => {
                    datatp.push({...item, text: item.title});
                });
                this.listtopic = datatp
            })
        },
        sendSupports (e) {
            e.preventDefault();
            // $('.loading_show').show().fadeIn(10)
            if(this.token != null){
                this.axios.post(this.api_sendSupport, 
                    {
                        topic_id: this.topic,
                        content: this.content,
                        support_id: 0
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    // $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.showModalsp = false,
                    this.getListsp()
                    this.topic_id = this.title = this.content = ''
                }).catch(e => {
                    // $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                let nameSecsion = 'Khách' + secsion.substring(10, 14)
                this.axios.post(this.api_sendSupportNologin, 
                    {
                        topic_id: this.topic,
                        content: this.content,
                        support_id: 0,
                        name: nameSecsion,
                        session_id: secsion
                    }
                ).then((response) => {
                    // $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.showModalsp = false,
                    this.getListsp()
                    this.topic_id = this.title = this.content = ''
                }).catch(e => {
                    // $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
            return false;
        },
        deleteSupport (event) {
            event.preventDefault();
            let idSP = event.target.getAttribute('data_id')
            $('.loading_show').show().fadeIn(10)
            if(this.token != null){
                this.axios.post(this.api_spDelete, 
                    {
                        id: idSP
                    },
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.success,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.getListsp()
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                this.axios.post(this.api_spDeleteNologin, 
                    {
                        id: idSP,
                        session_id: secsion
                    }
                ).then((response) => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'success',
                        title: response.data.success,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    this.getListsp()
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
            return false;
        },
        getListspdt (event) {
            $('.social_icon').hide()
            $('.root_sp_dt').show()
            if(this.checkSend == 0){
                this.id = event.target.getAttribute('data_id')
                $('.id_support').val(this.id)
                this.name_sp = event.target.getAttribute('data_title')
                $('.title_support').val(this.name_sp)
            }else{
                this.id = $('.id_support').val()
                this.name_sp = $('.title_support').val()
            }
            if(this.token != null){
                this.axios.get(this.api_spDetail + '?support_id=' + this.id, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    this.listtp = response.data.list
                    this.last_id = response.data.last_id
                    let arr_lt = response.data.data
                    this.listspdt = arr_lt.reverse()
                    setTimeout(() => {
                        var hg = $('.scroll_div_support').prop('scrollHeight');
                        $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                    }, 500);
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                this.axios.get(this.api_spDetailNologin + '?support_id=' + this.id + '&session_id=' + secsion).then((response) => {
                    this.listtp = response.data.list
                    this.last_id = response.data.last_id
                    let arr_lt = response.data.data
                    this.listspdt = arr_lt.reverse()
                    setTimeout(() => {
                        var hg = $('.scroll_div_support').prop('scrollHeight');
                        $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                    }, 500);
                })
            }
        },
        sendSupport (e) {
            e.preventDefault();
            $('.loading_show').show().fadeIn(10)
            this.id = $('.id_support').val()
            if(this.token != null){
                let url_image = document.getElementById('file_img_sp').files[0]
                const form = new FormData();
                form.append('support_id', this.id);
                form.append('url_image', url_image);
                form.append('content', this.content);
                this.axios.post(this.api_sendSupport, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    this.checkSend = 1
                    $('#loading_show').css('display', 'none')
                    this.other  = response
                    this.content = ''
                    $("#file_img_sp").val('');
                    if(response.data.data.mess_auto == 1){
                        let arrPush = {
                            id: response.data.data.id,
                            type_mes: response.data.data.type_mes,
                            content: response.data.data.content,
                            ngay_tao: response.data.data.ngay_tao,
                            type: response.data.data.type,
                            url_image: response.data.data.url_image
                        }
                        let arrPush1 = {
                            id: response.data.data.mess_reply.id,
                            type_mes: response.data.data.mess_reply.type_mes,
                            content: response.data.data.mess_reply.content,
                            ngay_tao: response.data.data.mess_reply.ngay_tao,
                            type: response.data.data.mess_reply.type,
                            url_image: response.data.data.mess_reply.url_image
                        }
                        this.listspdt = this.listspdt.concat(arrPush).concat(arrPush1)
                        this.listtp = response.data.data.data_topic
                        this.last_id = response.data.data.mess_reply.id
                        setTimeout(() => {
                            var hg = $('.scroll_div_support').prop('scrollHeight');
                            $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                        }, 1000);
                    }else{
                        this.getListspdt()
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                let nameSecsion = 'Khách' + secsion.substring(10, 14)
                let url_image = document.getElementById('file_img_sp').files[0]
                const form = new FormData();
                form.append('support_id', this.id);
                form.append('url_image', url_image);
                form.append('content', this.content);
                form.append('session_id', secsion);
                form.append('name', nameSecsion);
                this.axios.post(this.api_sendSupportNologin, 
                    form
                ).then((response) => {
                    this.checkSend = 1
                    $('#loading_show').css('display', 'none')
                    this.other  = response
                    this.content = ''
                    $("#file_img_sp").val('');
                    if(response.data.data.mess_auto == 1){
                        let arrPush = {
                            id: response.data.data.id,
                            type_mes: response.data.data.type_mes,
                            content: response.data.data.content,
                            ngay_tao: response.data.data.ngay_tao,
                            type: response.data.data.type,
                            url_image: response.data.data.url_image
                        }
                        let arrPush1 = {
                            id: response.data.data.mess_reply.id,
                            type_mes: response.data.data.mess_reply.type_mes,
                            content: response.data.data.mess_reply.content,
                            ngay_tao: response.data.data.mess_reply.ngay_tao,
                            type: response.data.data.mess_reply.type,
                            url_image: response.data.data.mess_reply.url_image
                        }
                        this.listspdt = this.listspdt.concat(arrPush).concat(arrPush1)
                        this.listtp = response.data.data.data_topic
                        this.last_id = response.data.data.mess_reply.id
                        setTimeout(() => {
                            var hg = $('.scroll_div_support').prop('scrollHeight');
                            $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                        }, 1000);
                    }else{
                        this.getListspdt()
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
            return false;
        },
        submitImg (event) {
            event.preventDefault();
            $('.listtp_other').hide()
            $('.loading_show').show().fadeIn(10)
            this.id = $('.id_support').val()
            if(this.token != null){
                let url_image = document.getElementById('file_img_sp').files[0]
                const form = new FormData();
                form.append('support_id', this.id);
                form.append('url_image', url_image);
                form.append('content', this.content);
                this.axios.post(this.api_sendSupport, 
                    form,
                    {
                        headers: {
                            Authorization: 'Bearer ' + this.token
                        }
                    }
                ).then((response) => {
                    this.checkSend = 1
                    $('#loading_show').css('display', 'none')
                    this.other  = response
                    this.content = ''
                    $("#file_img_sp").val('');
                    if(response.data.data.mess_auto == 1){
                        let arrPush = {
                            id: response.data.data.id,
                            type_mes: response.data.data.type_mes,
                            content: response.data.data.content,
                            ngay_tao: response.data.data.ngay_tao,
                            type: response.data.data.type,
                            url_image: response.data.data.url_image
                        }
                        let arrPush1 = {
                            id: response.data.data.mess_reply.id,
                            type_mes: response.data.data.mess_reply.type_mes,
                            content: response.data.data.mess_reply.content,
                            ngay_tao: response.data.data.mess_reply.ngay_tao,
                            type: response.data.data.mess_reply.type,
                            url_image: response.data.data.mess_reply.url_image
                        }
                        this.listspdt = this.listspdt.concat(arrPush).concat(arrPush1)
                        this.listtp = response.data.data.data_topic
                        this.last_id = response.data.data.mess_reply.id
                        setTimeout(() => {
                            var hg = $('.scroll_div_support').prop('scrollHeight');
                            $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                        }, 1000);
                    }else{
                        this.getListspdt()
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                let nameSecsion = 'Khách' + secsion.substring(10, 14)
                let url_image = document.getElementById('file_img_sp').files[0]
                const form = new FormData();
                form.append('support_id', this.id);
                form.append('url_image', url_image);
                form.append('content', this.content);
                form.append('session_id', secsion);
                form.append('name', nameSecsion);
                this.axios.post(this.api_sendSupportNologin, 
                    form
                ).then((response) => {
                    this.checkSend = 1
                    $('#loading_show').css('display', 'none')
                    this.other  = response
                    $("#file_img_sp").val('');
                    if(response.data.data.mess_auto == 1){
                        let arrPush = {
                            id: response.data.data.id,
                            type_mes: response.data.data.type_mes,
                            content: response.data.data.content,
                            ngay_tao: response.data.data.ngay_tao,
                            type: response.data.data.type,
                            url_image: response.data.data.url_image
                        }
                        let arrPush1 = {
                            id: response.data.data.mess_reply.id,
                            type_mes: response.data.data.mess_reply.type_mes,
                            content: response.data.data.mess_reply.content,
                            ngay_tao: response.data.data.mess_reply.ngay_tao,
                            type: response.data.data.mess_reply.type,
                            url_image: response.data.data.mess_reply.url_image
                        }
                        this.listspdt = this.listspdt.concat(arrPush).concat(arrPush1)
                        this.listtp = response.data.data.data_topic
                        this.last_id = response.data.data.mess_reply.id
                        setTimeout(() => {
                            var hg = $('.scroll_div_support').prop('scrollHeight');
                            $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                        }, 1000);
                    }else{
                        this.getListspdt()
                    }
                }).catch(e => {
                    $('#loading_show').css('display', 'none')
                    this.$swal({
                        position: 'top-end',
                        icon: 'error',
                        title: e.response.data.message,
                        showConfirmButton: false,
                        timer: 3000
                    })
                })
            }
            return false;
        },
        clickLoadSupport (event) {
            this.id = $('.id_support').val()
            let idtp = event.target.getAttribute('data_id')
            this.axios.get(this.api_listtopic + '?topic_id=' + idtp + '&support_id=' + this.id).then((response) => {
                this.other = response
            })
            if(this.token != null){
                this.axios.get(this.api_spDetail + '?support_id=' + this.id, {
                    headers: {
                        Authorization: 'Bearer ' + this.token
                    }
                }).then((response) => {
                    this.listtp = response.data.list
                    this.last_id = response.data.last_id
                    let arr_lt = response.data.data
                    this.listspdt = arr_lt.reverse()
                    setTimeout(() => {
                        var hg = $('.scroll_div_support').prop('scrollHeight');
                        $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                    }, 500);
                })
            }else{
                let secsion = sessionStorage.getItem("session");
                this.axios.get(this.api_spDetailNologin + '?support_id=' + this.id + '&session_id=' + secsion).then((response) => {
                    this.listtp = response.data.list
                    this.last_id = response.data.last_id
                    let arr_lt = response.data.data
                    this.listspdt = arr_lt.reverse()
                    setTimeout(() => {
                        var hg = $('.scroll_div_support').prop('scrollHeight');
                        $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                    }, 500);
                })
            }
        },
        connectSupport () {
            var thisSk = this
            var socket = SocketIO(this.link_web,{ transports: ['websocket', 'polling', 'flashsocket'] });
            socket.on('chat:support',function(result){
                if(result.data.url_image == null){
                    if(result.data.type == 2 || (result.data.type == 1 && result.data.type_mes != 1)){
                        let arrPush = {
                            id: result.data.id,
                            type_mes: result.data.type_mes,
                            content: result.data.content,
                            ngay_tao: result.data.ngay_tao,
                            type: result.data.type,
                            url_image: result.data.url_image
                        }
                        thisSk.listspdt = thisSk.listspdt.concat(arrPush)
                        setTimeout(() => {
                            var hg = $('.scroll_div_support').prop('scrollHeight');
                            $('.scroll_div_support').animate({ scrollTop: hg+100 }, 5);
                        }, 1000);
                    }
                }
            });
        },
        closeSupport () {
            $('.root_sp_dt').hide()
            this.listspdt = []
            this.last_id = ''
            $('.social_icon').show()
        },
        formatDates (date) {
            let datePart = date.match(/\d+/g)
            let year = datePart[4].substring(0)
            let month = datePart[3]
            let day = datePart[2]
            return year + '-' + month + '-' + day;
        },
        isSameUser (currentMessage, diffMessage) {
            var d = ''
            if(typeof diffMessage === 'undefined'){
                d = this.formatDates(currentMessage.ngay_tao)
            }else{
                d = this.formatDates(diffMessage.ngay_tao)
            }
            var d1 = this.formatDates(currentMessage.ngay_tao)
            if(diffMessage && diffMessage.type === currentMessage.type && d == d1){
                $('.message_'+diffMessage.id + ' .ngaytao_chat').addClass('removeDiv')
            }
            return true;
        }
    },
    beforeUnmount () {
        $('.social_icon').show()
    }
}
</script>
<style>
</style>
